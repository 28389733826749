import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';
import moment from "moment";
export const getUnits = createAsyncThunk('main/sttourmap/stTourPlans/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getStTours = createAsyncThunk('main/sttourmap/stTourPlans/getStTours2', async (searchParams) => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 } , headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return data.st_tours;
});

export const getStVehicleTour = createAsyncThunk('main/sttourmap/stTourPlans/stvehicletoursummaries', async (searchParams) => {
  const response = await axios.get(`api/v1/stvehicletoursummaries`, { params: { limit: 0 } , headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return data.stvehicletoursummaries;
});

export const getStTourDate = createAsyncThunk('main/sttourmap/stTourPlans/getStTours3', async (obj) => {
  let {date, query,index} = obj;
  const response = await axios.get(`api/v1/toursdate`, { params: {date :date,index:index,query:query } , headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return data;
});

export const getStVehicles = createAsyncThunk('main/sttourmap/stTourPlans/getStTours4', async (obj) => {
  let {date, query,index} = obj;
  const response = await axios.get(`api/v1/vehicledate`, { params: {date :date,index:index,query:query } , headers: { 'X-UI-State': 'stTourPlan.settings.stTourPlans' } });
  const data = await response.data;

  return data;
});


export const getAddress = createAsyncThunk('main/sttourmap/stTourPlans/getStTours5', async (obj) => {
  const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, { params: obj });
  const data = await response;

  return data;
});


export const postMapFilter = createAsyncThunk('main/sttourmap/stTourPlans/post', async (searchParams) => {
  const response = await axios.post(  '/api/v1/bins/searches', searchParams);
  const data = await response.data;

  return data.st_bin_events;
});

export const getBinsFilter = createAsyncThunk('main/sttourmap/stTourPlans/get', async (searchParams) => {
  const response = await axios.get(  '/api/v1/bins/filter');
  const data = await response.data;

  return data;
});

const stTourSlice = createSlice({
  name: 'main/sttourmap/stTourPlans',
  initialState: {
    searchText: '',
    loadingStTourPlans: false,
    loadingUnits: false,
    selectedRow: 100,
    errorDuplicationName:  false,
    isUpdated : false,
    savingStTourPlan: false,
    loadingStTours: true,
    stToursAdded:[{"id" : 1, "name" : "", "type":1}],
    elements:[],
    elems2:[],
    address: '',
   // stTourPlans: stTourPlansAdapter.getInitialState({ }),
    stTourPlansCount: 0,
   // stTours: stToursAdapter.getInitialState({ }),
 //   units: unitsAdapter.getInitialState({ }),
   // roles: rolesAdapter.getInitialState({ }),
    stTours : [],
    isNotEmptied: true,
    isEmptied: true,
    isDeepScanGood: true,
    isDeepScanMiddle: true,
    isDeepScanBad: true,
    markers : [],
    vehicles:[],
    bounds2:[],
    red:[],green:[],yellow :[],
    query:{},
    selectedTours : [],
    hasError: false,
    isDataDirty: false,
    loadingMarkers: false,
    loadingMarkersField : true,
    dates: [],
  },
  reducers: {
    setSelectedTours: {
      reducer: (state, action) => {
        state.selectedTours = action.payload;
      },
    },
    addTour : {
      reducer: (state, action) => {
       state.elements.push({"date": new Date(),"tours" : state.stTours,"vehicles" :state.vehicles})
      }
    },
    addLine : {
      reducer: (state, action) => {
        let {date,vehicle,tour,selectedRow} = action.payload;
        let vehicleSelected = null;
        let tourSelected = null;
        state.vehicles.forEach(function(el) {
          if (vehicle && el.id == vehicle.id) {
            vehicleSelected = el;
          }
        })
        state.stTours.forEach(function(el) {
          if (tour && el.id == tour.id) {
            tourSelected = el;
          }
        })
        state.elements = [];
        state.elements.push({"date": date,"vehicle":vehicleSelected, "tour":tourSelected,"tours" : state.stTours,"vehicles" :state.vehicles})
        state.isUpdated = true;
        state.selectedRow= selectedRow;
        state.loadingStTours= false;
      }
    },
    deletePreference : {
      reducer: (state, action) => {
        let index = action.payload;
        let el = state.elements.at(index);
        el.vehicle = "";
        el.tour = "";
      }
    },
    updateDate : {
      reducer: (state, action) => {
        let date = action.payload.date;
        let index = action.payload.index;
        let el = state.elements.at(index);
        el.date = date;
        state.isDataDirty = false;
      }
    },
    setIsNotEmptied: {
      reducer: (state, action) => {
        state.isNotEmptied = action.payload;
      }
    },
    setIsEmptied: {
      reducer: (state, action) => {
        state.isEmptied = action.payload;
      }
    },
    setIsDeepScanGood: {
      reducer: (state, action) => {
        state.isDeepScanGood = action.payload;
      }
    },
    setIsDeepScanMiddle: {
      reducer: (state, action) => {
        state.isDeepScanMiddle = action.payload;
      }
    },
    setIsDeepScanBad: {
      reducer: (state, action) => {
        state.isDeepScanBad = action.payload;
      }
    },
    setQuery: {
      reducer: (state, action) => {
        state.query = action.payload;
      }
    },
    updateVehicle : {
      reducer: (state, action) => {
        let vehicle = action.payload.vehicle;
        let index = action.payload.index;
        let el = state.elements.at(index);
        el.vehicle = vehicle;
        state.isDataDirty = false;
      }
    },
    updateTour : {
      reducer: (state, action) => {
        let tour = action.payload.tour;
        let index = action.payload.index;
        let el = state.elements.at(index);
        el.tour = tour;
        state.isDataDirty = false;
      }
    },
    deleteLine: {
      reducer: (state, action) => {
       // let tour = action.payload.tour;
        let index = action.payload;
        state.elements.splice(index, 1);
        //update fields for delete line.
        if (state.query && state.query.length > 0) {
          state.query.splice(index, 1);
          let arr = [];
          state.green.map(function (el, ind2) {
            if (el.index == index) {
              // state.green.splice(ind2,1);
            } else {
              arr.push(el);
            }
          });
          state.green = arr;
          arr = [];
          state.red.map(function (el, ind2) {
            if (el.index == index) {
              //state.red.splice(ind2,1);
            } else {
              arr.push(el);
            }
          })
          state.red = arr;
          arr = [];
          state.yellow.map(function (el, ind2) {
            if (el.index == index) {
              //state.yellow.splice(ind2,1);
            } else {
              arr.push(el);
            }
          })
          state.yellow = arr;
        }
      }
    }
  },
  extraReducers: {
    [getStTours.fulfilled](state, { payload }) {
     // stToursAdapter.setAll(state.stTours, payload.st_tours);
      state.loadingStTours = false;
      //state.stToursAdded =  [{"id" : 1, "name" : "", "type":1}]
      state.stTourPlansCount = payload.count;
      state.stTours = payload;
      state.isDataDirty =false;
      console.log(payload);
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      //stToursAdapter.removeAll(state.stTours);
      state.stToursAdded =  [{"id" : 1, "name" : "", "type":1}]
      state.stTourPlansCount = 0;
    //  state.stTours = [];
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
      //state.savingStTourPlan = false;
      state.stTourPlansCount = 0;
    //  state.stTours = [];

    },

    [getUnits.fulfilled](state, { payload }) {
      //unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      //unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
    [getStVehicles.fulfilled](state, { payload }) {
      state.loadingUnits = false;
      if (parseInt(payload.offset) == 100) {
        state.vehicles = payload.units
        state.loadingStTours = true;
      } else {
        let el = state.elements.at(payload.offset);
        el.vehicles = payload.units;
        el.vehicle = "";
        //el.tour = "";
        state.elements.at[payload.offset] = el;
        state.loadingStTours = false;
        if (payload.units && payload.units.length == 1) {
          el.vehicle = payload.units.at(0);
        }
      }
    },
    [getStVehicles.pending]: (state) => {
    //  state.loadingStTours = true;
      //unitsAdapter.removeAll(state.units);
    },
    [getStVehicles.rejected]: (state) => {
      state.loadingUnits = false;
    },
    [getStTourDate.fulfilled](state, { payload }) {
      //unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
      if (parseInt(payload.offset) == 100) {
          state.stTours = payload.st_tours;
          state.loadingStTours = true;
      } else {
        state.loadingStTours = false;
        let el = state.elements.at(payload.offset);
        el.tours = payload.st_tours;
        el.tour = "";
        state.elements.at[payload.offset] = el;
        if (payload.st_tours && payload.st_tours.length == 1) {
          el.tour = payload.st_tours.at(0);
        }
      }
    },
    [getStTourDate.pending]: (state) => {
      state.loadingUnits = true;
    //  state.loadingStTours = true;
      //unitsAdapter.removeAll(state.units);
    },
    [getStTourDate.rejected]: (state) => {
      state.loadingUnits = false;
    },
    [postMapFilter.fulfilled](state, { payload }) {
      state.markers = payload;
      state.loadingMarkers = false;
      state.loadingMarkersField = false;
      let array = [];
      state.bounds2 = [];
      state.red = [];
      state.yellow = [];
      state.green = [];
      state.isUpdated = false;
      let ind = 0;
    //  bounds2.push(L.marker([]));
      payload.map(function (el) {
        let date = moment(el.date);
        state.query.map(function (queryItem,index) {
          let date2 = moment(queryItem.date);
          let date3 = moment(queryItem.date).add(1, 'day');
          if (date >= date2 && date <= date3 && queryItem.vehicle == el.vehicle.id) {
            // el.index = index;
            const item = {
              ...el,
              index: index
            }
            queryItem.array.push(item);
            state.bounds2.push([item.position.latitude, item.position.longitude]);
            if (item.result== 0) {
              state.green.push(item);
            }
            if (item.result== 1) {
              state.yellow.push(item);
            }
            if (item.result== 2) {
              state.red.push(item);
            }
          }
        });
        ind++;
      });
      console.log(state.query);
    },
    [postMapFilter.pending]: (state) => {
      state.markers = [];
      state.loadingMarkers = true
      state.loadingMarkersField = true;
    },
    [postMapFilter.rejected]: (state) => {
      state.markers = [];
      state.loadingMarkers = true
    },
    [getBinsFilter.fulfilled](state, { payload }) {
      state.markers = payload;
      state.loadingStTours = false;
      let array = [];
      state.bounds2 = [];
      state.elems2 = state.elements;
      state.red = [];
      state.yellow = [];
      state.isUpdated = false;
      state.green = [];
      let ind = 0;
      // state.elements = [];
      let query = [];
      if (payload.st_vehicle_tour_summaries.length > 0) {
        if (state.elems2.length == 0){
          payload.st_vehicle_tour_summaries.map(function (el) {
            state.elements.push({
              "date": new Date(),
              "vehicle": el.vehicle,
              "tour": el.stTour,
              "tours": state.stTours,
              "vehicles": state.vehicles
            })
          });
          console.log(payload);
          console.log(state.stTours);
          let ind = 0;
          _.map(state.elements, function (el) {
            let elementObject = {
              date : moment(el.date).format("YYYY-MM-DD"),
              format : moment(el.date).format("DD.MM.YY"),
              vehicle : el.vehicle ? el.vehicle.id : "",
              vehicleName:  el.vehicle ? el.vehicle.licencePlat : "",
              tour : el.tour ? el.tour.id : "",
              tourName : el.tour ? el.tour.name : "",
              array : [],
            };
            query.push(elementObject);
          });
        }

      } else {
        if (state.elements && state.elements.length == 0) {
          state.elements.push({"date": new Date(), "tours": state.stTours, "vehicles": state.vehicles});
          state.isDataDirty = true;
        }
      }
      state.markers = payload.st_bin_events;
      payload.st_bin_events.map(function (el) {
        let date = moment(el.date);
        query.map(function (queryItem,index) {

          let date2 = moment(queryItem.date);
          let date3 = moment(queryItem.date).add(1, 'day');
          if (date >= date2 && date <= date3 && queryItem.vehicle == el.vehicle.id) {
            // el.index = index;
            const item = {
              ...el,
              index: index
            }
            queryItem.array.push(item);
            state.bounds2.push([item.position.latitude, item.position.longitude]);
            if (item.result== 0) {
              state.green.push(item);
            }
            if (item.result== 1) {
              state.yellow.push(item);
            }
            if (item.result== 2) {
              state.red.push(item);
            }
          }
        });
        ind++;
      });
      state.query = query;
      state.loadingMarkersField = false;
      state.isUpdated = false;
    },
    [getBinsFilter.pending]: (state) => {
      state.markers = [];
      state.loadingMarkers = true
      //state.loadingStTours = true;
    },
    [getAddress.rejected]: (state) => {
      state.address = '';
    },
    [getAddress.pending]: (state) => {
      state.address = '';
    },
    [getAddress.fulfilled](state, { payload }) {
      if (payload && payload.length > 0) {
        var r = payload[0];
        if (r) {
          state.address = (r.name ? r.name : r.html);
        }
      }
    },
    [getStVehicleTour.rejected]: (state) => {
      state.address = '';
    },
    [getStVehicleTour.pending]: (state) => {
      state.address = '';
    },
    [getStVehicleTour.fulfilled](state, { payload }) {
      state.dates= payload.map(tourView => new Date(tourView.dateTime));

    },

  },
});

export const {setSelectedTours,addLine,addTour,updateDate,updateVehicle,updateTour,deleteLine,setQuery,deletePreference,
  setIsNotEmptied, setIsDeepScanGood, setIsDeepScanMiddle, setIsDeepScanBad, setIsEmptied
} = stTourSlice.actions;

export default stTourSlice.reducer;
