import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiUrlConfig from 'app/apiUrlConfig';
import { authRoles } from 'app/auth';
import KocoUtils from 'app/utils/KocoUtils';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { liveDataBody, liveDataBodyNew, liveDataBodySweeper, liveDataChassis, liveDataChassisNew, liveDataChassisNewBluePower, liveDataChassisSweeper, liveDataGeneral, liveDataGeneralNew, liveDataGeneralSweeper, liveDataLifter, liveDataSweeper } from '../tabs/LiveDataSignals';

export const getUnit = createAsyncThunk('main/cockpit/unitDetail/getUnit', async (id) => {

  const response = await axios.get(`api/v1/units/${id}`, { headers: { 'X-UI-State': 'kocobox.list' } });
  const responseCompanies = await axios.get(`api/v1/passivevehicles/${id}/company`);
  let data = await response.data;

  if (!data)
    return;

  const dataCompanies = await responseCompanies.data;
  if(data && dataCompanies && dataCompanies.passivevehiclecompanyaccesses){
    data.companyAccesses = dataCompanies.passivevehiclecompanyaccesses.map(access => access);
  }

  data.isNewRcv = (!!data.currentVersion && data.currentVersion == 2) ? true : false;

  return data;
});

export const getActiveErrorsCount = createAsyncThunk('main/cockpit/unitDetail/getActiveErrorsCount', async (id) => {

  const response = await axios.post(apiUrlConfig.POST_PASSIVE_VEHICLE_ACTIVE_ERRORS, { ids: [id] });
  const data = await response.data;

  if (!data || !data.value || data.value.length === 0)
    return;

  return data.value[0];
},
// {
//   condition: (id, { getState, extra }) => {
//     const { users } = getState()
//     const fetchStatus = users.requests[id]
//     if (fetchStatus === 'fulfilled' || fetchStatus === 'loading') {
//       // Already fetched or in progress, don't need to re-fetch
//       return false
//     }
//   },
// }
);

export const getImei = createAsyncThunk('main/cockpit/unitDetail/getImei', async (unit) => {
  const response = await axios.get(`api/v1/units/${unit.id}/values/${1}`);
  const data = await response.data;
  return data;
});

export const getDefaultBodyInformation = createAsyncThunk('main/cockpit/unitDetail/getDefaultBodyInformation', async (unit) => {
  if (unit.id){
    const response = await axios.get(`api/v1/passivevehicles/${unit.id}/info`);
    const data = await response.data;
    return data;
  }
});

export const getPayload = createAsyncThunk('main/cockpit/unitDetail/getPayload', async (unit) => {
  const response = await axios.get(apiUrlConfig.GET_UNITS+"/"+unit+ apiUrlConfig.GET_LOAD_DATA, { params: { signaltype: 'payload' } });
  const data = await response.data;
  return data.data;
});

export const getTurbineSpeed = createAsyncThunk('main/cockpit/unitDetail/getTurbineSpeed', async (unit) => {
  const response = await axios.get(apiUrlConfig.GET_UNITS+"/"+unit+ apiUrlConfig.GET_LOAD_DATA, { params: { signaltype: 'turbinespeed' } });
  const data = await response.data;
  return data.data;
});

export const loadAllLiveData2 = createAsyncThunk('main/cockpit/unitDetail/getAllLiveData', async (type, { getState }) => {
  const { main, i18n } = getState();
  const unit = main.cockpit.unitDetail.unit;
  const response = await axios.get(`api/v1/units/${unit.id}/livedatas/${type}`);
  const data = await response.data;
  if (!data)
    return;

  return {data: data, language: i18n.language};
});

export const getBodyInformation = createAsyncThunk('main/cockpit/unitDetail/getBodyInformation', async (type, { getState }) => {
  const { main, i18n } = getState();
  const unit = main.cockpit.unitDetail.unit;
  const response = await axios.get(`api/v1/units/${unit.id}/values/${type}`);
  const data = await response.data;
  if (!data)
    return;

  return {data: data, language: i18n.language};
});

export const getLifterInformation = createAsyncThunk('main/cockpit/unitDetail/getLifterInformation', async (unit ,{ getState }) => {
  const response = await axios.get(`api/v1/units/${unit}/lifterinformation`);
  const data = await response.data;
  if (!data)
    return {};

  return data;
});

export const getOptimizationCompaction = createAsyncThunk('main/cockpit/unitDetail/getOptimizationCompaction', async (unit) => {

  const version = unit.currentVersion ? unit.currentVersion : 1;
  const response = await axios.post(apiUrlConfig.POST_LIVE_DATA, { signalIds: [version == 1 ? '2001B' : '20013'], ids: [unit] });
  const data = await response.data;

  return data;
});

export const patchGeneralInfo = createAsyncThunk('main/cockpit/bodyParameterUpdate/patchGeneralInfo', async (model, { getState }) => {
  const { main } = getState();
  const { unit } = main.cockpit.unitDetail;
  let payload = {
    passive_vehicle: {
      internalFleetManager: model.internalFleetManager,
      licencePlat: model.licencePlat,
      firstInstallation: model.firstInstallation,
      // truckPicture: model.truckPicture
    }
  };
  const response = await axios.patch(`api/v1/passivevehicles/${unit.id}`, payload);

  const data = await response.data;
  return data;
});


export const patchPicture = createAsyncThunk('main/cockpit/bodyParameterUpdate/patchPicture', async (model, { getState }) => {
    const { main } = getState();
    const { unit } = main.cockpit.unitDetail;
  
    let image;
    if (model.truckPicture.length > 1000) {
      const imageResponse = await axios.patch(
        `/api/v1/vehicles/${unit.id}/photo`,
        model.truckPicture
      );
      image = await imageResponse.data;
    } else {
      image = model.truckPicture;
    }
  
    return image;
  });

const getInitialState = () => {
  return {
    unit: null,
    liveDataGeneral: _.cloneDeep(liveDataGeneral),
    liveDataGeneralNew: _.cloneDeep(liveDataGeneralNew),
    liveDataGeneralSweeper: _.cloneDeep(liveDataGeneralSweeper),
    liveDataBodyNew: _.cloneDeep(liveDataBodyNew),
    liveDataBody: _.cloneDeep(liveDataBody),
    liveDataBodySweeper: _.cloneDeep(liveDataBodySweeper),
    loadingLiveData: false,
    // Second value is correct.
    // first value from today - first value from yesterday (4)
    // check later. (3)
    // how many hours until a complete service again 1 if value less >= 0)
    // add total counters.
    liveDataChassis: _.cloneDeep(liveDataChassis),
    liveDataChassisNew: _.cloneDeep(liveDataChassisNew),
    liveDataChassisNewBluePower: _.cloneDeep(liveDataChassisNewBluePower),
    liveDataChassisSweeper: _.cloneDeep(liveDataChassisSweeper),
    liveDataSweeper: _.cloneDeep(liveDataSweeper),
    liveDataLifter: _.cloneDeep(liveDataLifter),
    bodyInformation: {},
    lifterInformation: {},
    loading: true,
    loadingActiveErrorsCount: true,
    loadingPayload: true,
    loadingMileage: true,
    loadingImei: false,
    loadingDefaultBodyInformation: false,
    savingGeneralInfo:false,
    masterTabs: [
      {
        id: 1,
        translate: 'cockpit_tab_default_info_title',
        auth: [
          authRoles.ROLE_LIVE_READ,
          authRoles.ROLE_BASIC_INFO_READ,
          authRoles.ROLE_OPERATING_DATA_READ,
          authRoles.ROLE_BASIC_INFO_SOFTWARE_READ
        ]
      },
      {
        id: 2,
        translate: 'cockpit_tab_error_code',
        auth: [
          authRoles.ROLE_ERROR_MESSAGES_READ,
          authRoles.ROLE_FAUN_COMPLETE_READ
        ]
      },
      {
        id: 3,
        translate: 'cockpit_tab_parameter',
        auth: [
          authRoles.ROLE_PARAMETER_READ,
        ]
      },
      {
        id: 4,
        translate: 'cockpit_tab_parameter_update',
        auth: [
          authRoles.ROLE_PARAMETER_REQUEST_READ
        ]
      },
      {
        id: 5,
        translate: 'cockpit_service_book_title',
        auth: [
          authRoles.ROLE_SERVICE_BOOK_READ
        ]
      },
      {
        id: 6,
        translate: 'cockpit_scantec_deep_scan_setings_title',
        auth: [
          authRoles.ROLE_ST_DEEP_SCAN_SETTINGS_READ
        ]
      },
      {
        id: 7,
        translate: 'cockpit_ecotec',
        auth: [
          authRoles.ROLE_ECOTEC_FLEET_READ
        ]
      }
    ],
    selectedMasterTab: {
      id: 1,
      menuEl: null,
    },
  }
};

const unitDetailSlice = createSlice({
  name: 'main/cockpit/unitDetail',
  initialState: getInitialState(),
  reducers: {
    // setUnit: {
    //   reducer: (state, action) => {
    //     state.unit = action.payload;
    //   },
    // },
      resetUnit: {
      reducer: (state, action) => {
        state.unit = [];
        state = getInitialState();   
      },
    },
    setSelectedMasterTab: {
      reducer: (state, action) => {
        state.selectedMasterTab = action.payload;   
      },
    },
    setLiveDataSweeper: {
      reducer: (state, action) => {
        state.liveDataSweeper = action.payload;   
      },
    },
  },
  extraReducers: {
    [getUnit.fulfilled]: (state, { payload }) => {
      state.liveDataGeneral = _.cloneDeep(liveDataGeneral),
      state.liveDataGeneralNew = _.cloneDeep(liveDataGeneralNew),
      state.liveDataGeneralSweeper = _.cloneDeep(liveDataGeneralSweeper),
      state.liveDataBodyNew = _.cloneDeep(liveDataBodyNew),
      state.liveDataBody = _.cloneDeep(liveDataBody),
      state.liveDataBodySweeper = _.cloneDeep(liveDataBodySweeper),
      state.liveDataChassis = _.cloneDeep(liveDataChassis),
      state.liveDataChassisNew = _.cloneDeep(liveDataChassisNew),
      state.liveDataChassisNewBluePower = _.cloneDeep(liveDataChassisNewBluePower),
      state.liveDataChassisSweeper = _.cloneDeep(liveDataChassisSweeper),
      state.liveDataSweeper = _.cloneDeep(liveDataSweeper),
      state.liveDataLifter = _.cloneDeep(liveDataLifter),
      state.bodyInformation = {},
      state.lifterInformation = {},
      state.unit = payload;
    
      state.loading = false;
    },
    [getUnit.pending]: (state) => {
      state.loading = true
    },
    [getUnit.rejected]: (state) => {
      state.loading = false;
    },

    [getActiveErrorsCount.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.unit.activeErrorsCount = payload.count;
      }
      state.loadingActiveErrorsCount = false;
    },
    [getActiveErrorsCount.pending]: (state) => {
      state.loadingActiveErrorsCount = true
    },
    [getActiveErrorsCount.rejected]: (state) => {
      state.loadingActiveErrorsCount = false;
    },

    [getImei.fulfilled](state, { payload }) {
      state.bodyInformation.imei = (payload && payload.value) ? payload.value : '';
      state.loadingImei = false;
    },
    [getImei.pending]: (state) => {
      state.loadingImei = true
    },
    [getImei.rejected]: (state) => {
      state.loadingImei = false;
    },

    [getDefaultBodyInformation.fulfilled](state, { payload }) {
      state.bodyInformation.internalFleetManager = payload && payload.internalFleetManager ? payload.internalFleetManager : '';
      state.bodyInformation.licencePlat = payload && payload.licencePlat ? payload.licencePlat : '';
      state.bodyInformation.firstInstallation = payload && payload.firstInstallation ? payload.firstInstallation : "";
      state.bodyInformation.truckPicture = payload && payload.truckPicture ? payload.truckPicture : "";
      state.loadingDefaultBodyInformation = false;
    },
    [getDefaultBodyInformation.pending]: (state) => {
      state.loadingDefaultBodyInformation = true
    },
    [getDefaultBodyInformation.rejected]: (state) => {
      state.loadingDefaultBodyInformation = false;
    },

    [getPayload.fulfilled](state, { payload }) {
      if (payload){
      state.unit.payload = payload;
      }
      state.loadingPayload = false;
    },    
    [getPayload.pending]: (state) => {
      state.loadingPayload = true
    },
    [getPayload.rejected]: (state) => {
      state.loadingPayload = false;
    },

    [getTurbineSpeed.fulfilled](state, { payload }) {
      if (payload && payload.unit){
      state.unit.turbineSpeed = payload;
      }
      state.loadingTurbineSpeed = false;
    },
    [getTurbineSpeed.pending]: (state) => {
      state.loadingTurbineSpeed = true
    },
    [getTurbineSpeed.rejected]: (state) => {
      state.loadingTurbineSpeed = false;
    },

    [getLifterInformation.fulfilled](state, { payload }) {
      if (!payload)
        state.lifterInformation = {};
      else
        state.lifterInformation = payload;
    },
    [getBodyInformation.fulfilled](state, { meta, payload }) {
      const type = meta.arg;
      const language = payload.language;
      const notAvailable = language == 'de' ? 'Nicht Verfügbar' : 'not available';
      payload = payload.data;
      if (type == 2)
        state.bodyInformation.bodyNumber = payload && payload.value ? payload.value.replace('�', '') : '';

      // if (unit.isRcv) {
      if (type == 3)
        state.bodyInformation.bodyType = payload && payload.value ? payload.value.replace('�', '') : '';
      if (type == 4)
        state.bodyInformation.engineTorque = payload && payload.value ? payload.value : '';

      if (type == 5)
        state.bodyInformation.softwareVersionMasterUnit = payload && payload.value ? (payload.value.includes('00.00.00') || payload.value.includes('000000') ? 'Nicht Verfügbar' :
          payload.value.replace(/�/g, '')) : notAvailable;

      if (type == 6)
        state.bodyInformation.runTimeVersionMasterUnit = payload && payload.value ? (payload.value.replace('�', '').includes('000') ?
          notAvailable : payload.value.replace(/�/g, '')) : notAvailable;

      if (type == 7)
        state.bodyInformation.softwareVersionDisplayUnit = payload && payload.value ? (payload.value.replace('�', '').includes('00.00.00') || payload.value.includes('000000') ? notAvailable : payload.value.replace('�', '')) : '';

      if (type == 8)
        state.bodyInformation.runTimeDisplayUnit = payload && payload.value ? (payload.value.replace('�', '').includes('00.00.00') || payload.value.includes('000000') ? notAvailable : payload.value.replace('�', '')) : '';

      // } else {

      if (type == 10)
        state.bodyInformation.softwareVersionDisplay = payload && payload && payload.value ? (payload.value.replace('�', '').includes('00.00.00') || payload.value.includes('000000') ? notAvailable : payload.value.replace('�', '')) : '';

      if (type == 11)
        state.bodyInformation.hardwareVersionDisplay = payload && payload.value ? payload.value.replace('�', '') : '';

      if (type == 12)
        state.bodyInformation.hardwareVersionCm = payload && payload.value ? payload.value.replace('�', '') : '';

      if (type == 13)
        state.bodyInformation.softwareVersionCm = payload && payload.value ? payload.value.replace('�', '') : '';

      if (type == 14)
        state.bodyInformation.driveTypeName = payload && payload.value ? payload.value.replace('�', '') : '';

      if (type == 15)
        state.bodyInformation.sweeperName = payload && payload.value ? payload.value.replace('�', '') : '';
      // }
    },

    [loadAllLiveData2.pending]: (state) => {
      state.liveDataGeneral = _.cloneDeep(liveDataGeneral);
      state.liveDataGeneralNew = _.cloneDeep(liveDataGeneralNew);
      state.liveDataGeneralSweeper = _.cloneDeep(liveDataGeneralSweeper);
      state.liveDataBodyNew = _.cloneDeep(liveDataBodyNew);
      state.liveDataBody = _.cloneDeep(liveDataBody);
      state.liveDataBodySweeper = _.cloneDeep(liveDataBodySweeper);
      state.liveDataChassis = _.cloneDeep(liveDataChassis);
      state.liveDataChassisNew = _.cloneDeep(liveDataChassisNew);
      state.liveDataChassisNewBluePower = _.cloneDeep(liveDataChassisNewBluePower);
      state.liveDataChassisSweeper = _.cloneDeep(liveDataChassisSweeper);
      let liveDataSweeperCloned = _.cloneDeep(liveDataSweeper);
      state.loadingLiveData = true;
      if(state.unit){
        const liveDataSweepFiltered = _.filter(liveDataSweeperCloned, function (o) {
          if (o.group == state.unit.driveTypeGroup)
            return o;
        });
        state.liveDataSweeper = liveDataSweepFiltered;
      }
      else {
        state.liveDataSweeper = liveDataSweeperCloned;
      }
      state.liveDataLifter = _.cloneDeep(liveDataLifter);
    },

    [loadAllLiveData2.fulfilled](state, { meta, payload }) {
      const type = meta.arg;
      state.loadingLiveData = false;
      const language = payload.language;
      const overdue = language == 'de' ? 'überfällig' : 'overdue';
      const notActivated = language == 'de' ? 'Nicht aktiviert' : 'not activated';
      const householdWaste = language == 'de' ? 'Hausmüll' : 'household waste';      
      const organicWaste = language == 'de' ? 'Biomüll' : 'organic waste';      
      const paperWaste = language == 'de' ? 'Papier' : 'Paper';      
      const notOk = language == 'de' ? 'Nicht OK' : 'not OK';      
      const warning = language == 'de' ? 'Warnung' : 'warning';      

      if (state.unit.isRcv) {
        if (state.unit.isNewRcv) {    
          // Restangular.one('units', state.unit.id).one('livedatas', type).get({}).then(function (res) {

            if (payload.data) {
   
              _.each(payload.data, (lift) => {
                if (lift.signalId == 'g1' || lift.signalId == 'g2' || lift.signalId == 'g3' || lift.signalId == '30201') {
                  var data = lift.value;
                  if (data != '-' && data != '') {
                    data = (parseFloat(data) / 1000).toFixed(1).replace('.', ',');
                    data = KocoUtils.numberWithCommas(data);
                  }
      
                  lift.value = data;
                } else if (lift.signalId == '30502' || lift.signalId == '30503') {
                  lift.value = lift.value + '%';
    
                } else if (lift.signalId == 'l2' || lift.signalId == 'L01') {
                  lift.value = lift.value != '-' ? KocoUtils.numberWithCommas((parseInt(lift.value) / 3600).toFixed(0).replace('.', ',')) : '-';
                } else if (lift.signalId == 'L02') {
                  var y = 0;
                  var x = 0;
                  if (parseInt(lift.value) > 2147483647) {
                    var hex = (parseInt(lift.value).toString(16));
                    x = KocoUtils.hexToSignedInt(hex);
    
                  } else {
                    x = parseInt(lift.value) - 1;
                  }
                  y = x / 3600;
                  lift.value = lift.value != '-' ? KocoUtils.numberWithCommas(y.toFixed(0).replace('.', ',')) : '-';
                  var yourNumber = 4293746077;
                  var hexString = yourNumber.toString(16);
                } else if (lift.signalId == '20302') {
                  lift.value2 = (lift.value != '-') ? (parseFloat(lift.value) >= 0 ? 'OK' : overdue) : '-';
                  lift.value = lift.value != '-' ? KocoUtils.numberWithCommas(parseFloat(lift.value)) : '-';
                } else if (lift.signalId == '20301') {
                  lift.value = lift.value != '-' ? KocoUtils.numberWithCommas(parseFloat(lift.value)) : '-';
                } else if (lift.signalId == '20003' || lift.signalId == '20004') {
                  if (lift.value != '-') {
                    switch (parseInt(lift.value)) {
                      case 2:
                        lift.value = notOk;
                        break;
                      case 1:
                      case 3:
                      default:
                        lift.value = 'OK';
                        break;
                    }
                  } else {
                    lift.value = '-';
                  }
                } else if (lift.signalId == '20016') {
                  if (lift.value != '-') {
                    console.log('20016', lift, parseInt(lift.value));
                    switch (parseInt(lift.value)) {
                      case 1:
                        lift.value = householdWaste;
                        break;
                      case 2:
                        lift.value = organicWaste;
                        break;
                      case 4:
                        lift.value = 'DSD';
                        break;
                      case 8:
                        lift.value = paperWaste;
                        break;
                      default:
                        lift.value = notActivated;
                    }
                  } else {
                    lift.value = '-';
                  }
                } else {
                  lift.value = lift.value;
                }

                var objectChange = [];
                switch (type) {
                  case 12:
                    objectChange = state.liveDataGeneralNew;
                    break;
                  case 13:
                    objectChange = state.liveDataBodyNew;
                    break;
                  case 3:
                      objectChange = state.liveDataLifter;
                    break;
                  case 14:
                    objectChange = state.liveDataChassisNew;
                    break;
                  case 15:
                    objectChange = state.liveDataChassisNewBluePower;
                    break;
                }
                var test = _.find(objectChange, function (neObject) {
                  if (!Array.isArray(neObject.signalId))
                    return neObject.signalId == lift.signalId && !neObject.isSoftware;
                  else {
                    return neObject.signalId.includes(lift.signalId);
                  }
                });
                var test2 = _.find(objectChange, function (neObject) {
                  return neObject.signalId == lift.signalId && neObject.isSoftware;
                });
                if (test2 && test2.isSoftware) {
                  test2.value = lift.value2;
                  test2.date = lift.date;
    
                }
                if(test){
                  test.value = lift.value;
                  test.date = lift.date;
                }
              });
            }
    
        } else {
            if (payload.data) {
              _.each(payload.data, function (lift) {
                if (lift.signalId == 'g1' || lift.signalId == 'g2' || lift.signalId == 'g3' || lift.signalId == '20401') {
                  var data = lift.value;
                  if (data != '-' && data != '') {
                    data = (parseFloat(data) / 1000).toFixed(1).replace('.', ',');
                    data = KocoUtils.numberWithCommas(data);
                  }
                  lift.value = data;
                } else if (lift.signalId == '10502' || lift.signalId == '10503') {
                  lift.value = lift.value + '%';
    
                } else if (lift.signalId == 'l2' || lift.signalId == 'L01') {
                  lift.value = lift.value != '-' ? KocoUtils.numberWithCommas((parseInt(lift.value) / 3600).toFixed(0).replace('.', ',')) : '-';
                } else if (lift.signalId == 'L02') {
                  var y = 0;
                  var x = 0;
                  if (parseInt(lift.value) > 2147483647) {
                    var hex = (parseInt(lift.value).toString(16));
                    x = KocoUtils.hexToSignedInt(hex);
    
                  } else {
                    x = parseInt(lift.value) - 1;
                  }
                  y = x / 3600;
                  lift.value = lift.value != '-' ? KocoUtils.numberWithCommas(y.toFixed(0).replace('.', ',')) : '-';
                  var yourNumber = 4293746077;
                  var hexString = yourNumber.toString(16);
                } else if (lift.signalId == '20302') {
                  lift.value2 = (lift.value != '-') ? (parseFloat(lift.value) >= 0 ? 'OK' : overdue) : '-';
                  lift.value = lift.value != '-' ? KocoUtils.numberWithCommas(parseFloat(lift.value)) : '-';
                } else if (lift.signalId == '20301') {
                  lift.value = lift.value != '-' ? KocoUtils.numberWithCommas(parseFloat(lift.value)) : '-';
                } else if (lift.signalId == '20003' || lift.signalId == '20004') {
                  if (lift.value != '-') {
                    switch (parseInt(lift.value)) {
                      case 2:
                        lift.value = notOk;
                        break;
                      case 1:
                      case 3:
                      default:
                        lift.value = 'OK';
                        break;
                    }
                  } else {
                    lift.value = '-';
                  }
                } else if (lift.signalId == '2001B') {
                  if (lift.value != '-') {
                    switch (parseInt(lift.value)) {
                      case 1:
                        lift.value = householdWaste;
                        break;
                      case 2:
                        lift.value = organicWaste;
                        break;
                      case 4:
                        lift.value = 'DSD';
                        break;
                      case 8:
                        lift.value = paperWaste;
                        break;
                      default:
                        lift.value = notActivated;
                    }
                  } else {
                    lift.value = '-';
                  }
                } else {
                  lift.value = lift.value;
                }

                var objectChange = [];
                switch (type) {
                  case 1:
                    objectChange = state.liveDataGeneral;
                    break;
                  case 2:
                    objectChange = state.liveDataBody;
                    break;
                  case 3:
                      objectChange = state.liveDataLifter;
                    break;
                  case 4:
                    objectChange = state.liveDataChassis;
                    break;
                }
                var test = _.find(objectChange, function (neObject) {
                  if (!Array.isArray(neObject.signalId))
                    return neObject.signalId == lift.signalId && !neObject.isSoftware;
                  else {
                    return neObject.signalId.includes(lift.signalId);
                  }
                });
                var test2 = _.find(objectChange, function (neObject) {
                  return neObject.signalId == lift.signalId && neObject.isSoftware;
                });
                if (test2 && test2.isSoftware) {
                  test2.value = lift.value2;
                  test2.date = lift.date;
    
                }
                test.value = lift.value;
                test.date = lift.date;
    
              });
    
    
            }
        }
      } else {
          if (payload.data) {
            _.each(payload.data, function (lift) {
              var objectChange = [];
              switch (type) {
                case 5:
                  objectChange = state.liveDataGeneralSweeper;
                  break;
                case 6:
                  objectChange = state.liveDataBodySweeper;
                  break;
                case 7:
                case 8:
                case 9:
                case 10:
                  objectChange = state.liveDataSweeper;
                  break;
                case 11:
                  objectChange = state.liveDataChassisSweeper;
                  break;
              }
              var test = _.find(objectChange, function (neObject) {
                return neObject.signalId == lift.signalId && !neObject.isSoftware;
              });
    
              var test2 = _.find(objectChange, function (neObject) {
                return neObject.signalId == lift.signalId && neObject.isSoftware;
              });
              if (test) {
                if (test.signalId == 'g1' || test.signalId == '110201') {
                  var data = lift.value;
/*                   if (data != '-' && data != '') {
                    data = (parseFloat(data) / 1000).toFixed(1).replace('.', ',');
                    data = KocoUtils.numberWithCommas(data);
                  } */
                  lift.value = data;
                } /* else if (test.signalId == '110807' || test.signalId == '110808') {
                  test.value = lift.value + '%';
    
                } */ else {
                  test.value = lift.value;
                  test.date = lift.date;
                }
              }
/*               if (test2) {
                test2.date = lift.date;
                if (test2.singalId == '110807' || test2.signalId == '110808') {
                  if (parseInt(lift.value) > 50) {
                    test2.value == 'OK';
                  } else if (parseInt(lift.value) > 30) {
                    test2.value == warning;
                  } else {
                    test2.value == notOk;
                  }
                } else {
                  test2.value = (lift.value != '-') ? (parseFloat(lift.value) >= 0 ? 'OK' : overdue) : '-';
                }
              } */
    
            });
    
          }
      };
    },
    [getOptimizationCompaction.fulfilled](state, { payload }) {

      if(payload.value && payload.value.length > 0){
        state.unit.optimizationCompaction = parseInt(payload.value[0].doc.value);        
        state.unit.optimizationCompactionDate = payload.value[0].doc.date.date;        
      }

      state.loadingOptmizationCompaction = false;
    },
    [getOptimizationCompaction.pending]: (state) => {
      state.loadingOptmizationCompaction = true
    },
    [getOptimizationCompaction.rejected]: (state) => {
      state.loadingOptmizationCompaction = false;
    },

    [patchGeneralInfo.fulfilled](state, { payload }) {
      state.savingGeneralInfo = false;
    },
    [patchGeneralInfo.pending]: (state) => {
      state.savingGeneralInfo = true
    },
    [patchGeneralInfo.rejected]: (state) => {
      state.savingGeneralInfo = false;
    },
    
  },
});

export const { setUnit, setSelectedMasterTab, resetUnit, setLiveDataSweeper } = unitDetailSlice.actions;

export default unitDetailSlice.reducer;
