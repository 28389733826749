import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const StTourPlanNew = lazy(() => import('./st-tour-plan-new/StTourPlanNew'));
const StTourPlans = lazy(() => import('./st-tour-plans/StTourPlans'));

const SettingsStTourPlanConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'sttourplans',
      element: <StTourPlans />,
      auth: [authRoles.ROLE_ST_TOUR_PLAN_READ], 
    },
    {
      path: 'sttourplan/new',
      element: <StTourPlanNew mode="new" />,
      auth: [authRoles.ROLE_ST_TOUR_PLAN_WRITE], 
    },
    {
      path: 'sttourplan/import',
      element: <StTourPlanNew mode="import" />,
      auth: [authRoles.ROLE_ST_TOUR_PLAN_WRITE], 
    },
  ],
};

export default SettingsStTourPlanConfig;
