/**
 * Authorization Roles
 */
const authRoles = {

  ROLE_FLEET_TRACKING_READ: 'ROLE_FLEET_TRACKING_READ',
  ROLE_SERVICE_DASHBOARD_READ: 'ROLE_SERVICE_DASHBOARD_READ',
  ROLE_TOUR_ANALYZE_READ: 'ROLE_TOUR_ANALYZE_READ',
  ROLE_REPORTS_READ: 'ROLE_REPORTS_READ',
  ROLE_SCANTEC_READ: 'ROLE_SCANTEC_READ',
  ROLE_SCANTEC_WRITE: 'ROLE_SCANTEC_WRITE',
  ROLE_BASIC_INFO_READ: 'ROLE_BASIC_INFO_READ',
  ROLE_LIVE_READ: 'ROLE_LIVE_READ',
  ROLE_BASIC_INFO_SIGNAL: 'ROLE_BASIC_INFO_SIGNAL',  //added role for signal tab for truck cockpit
  ROLE_BASIC_INFO_SOFTWARE_READ: 'ROLE_BASIC_INFO_SOFTWARE_READ',
  ROLE_OPERATING_DATA_READ: 'ROLE_OPERATING_DATA_READ',
  ROLE_ERROR_MESSAGES_READ: 'ROLE_ERROR_MESSAGES_READ',
  ROLE_FAUN_COMPLETE_READ: 'ROLE_FAUN_COMPLETE_READ',
  ROLE_HANDOVERS_READ: 'ROLE_HANDOVERS_READ',
  // ROLE_PARAMETER:
  ROLE_PARAMETER_READ: 'ROLE_PARAMETER_READ',
  // ROLE_PARAMETER_REQUEST:
  ROLE_PARAMETER_REQUEST_READ: 'ROLE_PARAMETER_REQUEST_READ',
  ROLE_PARAMETER_REQUEST_WRITE: 'ROLE_PARAMETER_REQUEST_WRITE',
  ROLE_PARAMETER_REQUEST_REMOVE: 'ROLE_PARAMETER_REQUEST_REMOVE',
  ROLE_PARAMETER_REQUEST_WRITE_IMPORT_FILE: 'ROLE_PARAMETER_REQUEST_WRITE_IMPORT_FILE',
  ROLE_PARAMETER_REQUEST_WRITE_ALL_EXISTING: 'ROLE_PARAMETER_REQUEST_WRITE_ALL_EXISTING',
  // ROLE_SOFTWARE_UPDATE:
  ROLE_SOFTWARE_UPDATE_READ: 'ROLE_SOFTWARE_UPDATE_READ',
  // ROLE_ERROR_CODE:
  ROLE_ERROR_CODE_READ: 'ROLE_ERROR_CODE_READ',
  ROLE_ERROR_CODE_WRITE: 'ROLE_ERROR_CODE_WRITE',
  ROLE_ERROR_CODE_REMOVE: 'ROLE_ERROR_CODE_REMOVE',
  // ROLE_ROLE:
  ROLE_ROLE_READ: 'ROLE_ROLE_READ',
  ROLE_ROLE_WRITE: 'ROLE_ROLE_WRITE',
  ROLE_ROLE_REMOVE: 'ROLE_ROLE_REMOVE',
  // ROLE_COMPANY:
  ROLE_COMPANY_READ: 'ROLE_COMPANY_READ',
  ROLE_COMPANY_WRITE: 'ROLE_COMPANY_WRITE',
  ROLE_COMPANY_REMOVE: 'ROLE_COMPANY_REMOVE',
  // ROLE_USER_SETTINGS:
  ROLE_USER_READ: 'ROLE_USER_READ',
  ROLE_USER_WRITE: 'ROLE_USER_WRITE',
  ROLE_USER_REMOVE: 'ROLE_USER_REMOVE',
  'ROLE_USER_SETTINGS_READ': 'ROLE_USER_SETTINGS_READ',
  // ROLE_DEPOT
  ROLE_ECOTEC_FLEET_READ: 'ROLE_ECOTEC_FLEET_READ', 
  ROLE_DEPOT_READ: 'ROLE_DEPOT_READ',
  ROLE_DEPOT_WRITE: 'ROLE_DEPOT_WRITE',
  ROLE_DEPOT_REMOVE: 'ROLE_DEPOT_REMOVE',
  // ROLE_UNIT:
  ROLE_UNIT_READ: 'ROLE_UNIT_READ',
  ROLE_UNIT_WRITE: 'ROLE_UNIT_WRITE',
  ROLE_UNIT_REMOVE: 'ROLE_UNIT_REMOVE',
  ROLE_UNIT_SETTINGS_READ: 'ROLE_UNIT_SETTINGS_READ',
  // ROLE_TRUCK_GROUP:
  ROLE_TRUCK_GROUP_READ: 'ROLE_TRUCK_GROUP_READ',
  ROLE_TRUCK_GROUP_WRITE: 'ROLE_TRUCK_GROUP_WRITE',
  ROLE_TRUCK_GROUP_REMOVE: 'ROLE_TRUCK_GROUP_REMOVE',
  // ROLE_PROFILE:
  ROLE_PROFILE_READ: 'ROLE_PROFILE_READ',
  ROLE_PROFILE_WRITE: 'ROLE_PROFILE_WRITE',
  // ROLE_MODULE:
  ROLE_MODULE_READ: 'ROLE_MODULE_READ',
  ROLE_MODULE_WRITE: 'ROLE_MODULE_WRITE',
  ROLE_MODULE_REMOVE: 'ROLE_MODULE_REMOVE',
  // ROLE_FLEET_OVERVIEW:
  ROLE_FLEET_OVERVIEW_READ: 'ROLE_FLEET_OVERVIEW_READ',
  // ROLE_CONFIG:
  ROLE_CONFIG_READ: 'ROLE_CONFIG_READ',
  ROLE_CONFIG_WRITE: 'ROLE_CONFIG_WRITE',
  ROLE_CONFIG_REMOVE: 'ROLE_CONFIG_REMOVE',
  // ROLE_DEBUG_FILE:
  ROLE_DEBUG_FILE_READ: 'ROLE_DEBUG_FILE_READ',
  ROLE_DEBUG_FILE_REMOVE: 'ROLE_DEBUG_FILE_REMOVE',
  // INTERNAL_DEBUG_LOG_FILE:
  INTERNAL_DEBUG_LOG_FILE_READ: 'INTERNAL_DEBUG_LOG_FILE_READ',
  INTERNAL_DEBUG_LOG_FILE_WRITE: 'INTERNAL_DEBUG_LOG_FILE_WRITE',
  INTERNAL_DEBUG_LOG_FILE_REMOVE: 'INTERNAL_DEBUG_LOG_FILE_REMOVE',
  // ROLE_SERVICE_BOOK:
  ROLE_SERVICE_BOOK_READ: 'ROLE_SERVICE_BOOK_READ',
  ROLE_SERVICE_BOOK_WRITE: 'ROLE_SERVICE_BOOK_WRITE',
  ROLE_SERVICE_BOOK_REMOVE: 'ROLE_SERVICE_BOOK_REMOVE',
  // ROLE_UNIT_CONFIGURATION_FILE:
  ROLE_UNIT_CONFIGURATION_FILE_READ: 'ROLE_UNIT_CONFIGURATION_FILE_READ',
  ROLE_UNIT_CONFIGURATION_FILE_WRITE: 'ROLE_UNIT_CONFIGURATION_FILE_WRITE',
  ROLE_UNIT_CONFIGURATION_FILE_REMOVE: 'ROLE_UNIT_CONFIGURATION_FILE_REMOVE',
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',

  ROLE_GEOFENCE_READ: 'ROLE_GEOFENCE_READ',
  ROLE_GEOFENCE_WRITE: 'ROLE_GEOFENCE_WRITE',

  ROLE_PASSIVE_VEHICLE_GROUP_READ: 'ROLE_PASSIVE_VEHICLE_GROUP_READ',
  ROLE_PASSIVE_VEHICLE_GROUP_WRITE: 'ROLE_PASSIVE_VEHICLE_GROUP_WRITE',
  ROLE_PASSIVE_VEHICLE_GROUP_REMOVE: 'ROLE_PASSIVE_VEHICLE_GROUP_REMOVE',
  ROLE_PASSIVE_VEHICLE_GROUP_CHANGE_PRIVACY: 'ROLE_PASSIVE_VEHICLE_GROUP_CHANGE_PRIVACY',

  ROLE_SUBSCRIPTION_READ: 'ROLE_SUBSCRIPTION_READ',
  ROLE_SUBSCRIPTION_WRITE: 'ROLE_SUBSCRIPTION_WRITE',
  ROLE_SUBSCRIPTION_REMOVE: 'ROLE_SUBSCRIPTION_REMOVE',

  ROLE_SUBSCRIPTION_ERROR_READ: 'ROLE_SUBSCRIPTION_ERROR_READ',
  ROLE_SUBSCRIPTION_ERROR_WRITE: 'ROLE_SUBSCRIPTION_ERROR_WRITE',
  ROLE_SUBSCRIPTION_ERROR_REMOVE: 'ROLE_SUBSCRIPTION_ERROR_REMOVE',

  ROLE_SUBSCRIPTION_REPORT_READ: 'ROLE_SUBSCRIPTION_REPORT_READ',
  ROLE_SUBSCRIPTION_REPORT_WRITE: 'ROLE_SUBSCRIPTION_REPORT_WRITE',
  ROLE_SUBSCRIPTION_REPORT_REMOVE: 'ROLE_SUBSCRIPTION_REPORT_REMOVE',

  ROLE_SUBSCRIPTION_TOUR_KOCO_READ: 'ROLE_SUBSCRIPTION_TOUR_KOCO_READ',
  ROLE_SUBSCRIPTION_TOUR_KOCO_WRITE: 'ROLE_SUBSCRIPTION_TOUR_KOCO_WRITE',
  ROLE_SUBSCRIPTION_TOUR_KOCO_REMOVE: 'ROLE_SUBSCRIPTION_TOUR_KOCO_REMOVE',

  ROLE_EVENT_INFO_READ: 'ROLE_EVENT_INFO_READ',
  ROLE_EVENT_INFO_WRITE: 'ROLE_EVENT_INFO_WRITE',
  ROLE_EVENT_INFO_REMOVE: 'ROLE_EVENT_INFO_REMOVE',

  ROLE_SCANTECH_READ: 'ROLE_SCANTECH_READ',

  ROLE_ST_TOUR_READ: 'ROLE_ST_TOUR_READ',
  ROLE_ST_TOUR_WRITE: 'ROLE_ST_TOUR_WRITE',
  ROLE_ST_TOUR_REMOVE: 'ROLE_ST_TOUR_REMOVE',

  ROLE_ST_TOUR_PLAN_READ: 'ROLE_ST_TOUR_PLAN_READ',
  ROLE_ST_TOUR_PLAN_WRITE: 'ROLE_ST_TOUR_PLAN_WRITE',
  ROLE_ST_TOUR_PLAN_REMOVE: 'ROLE_ST_TOUR_PLAN_REMOVE',

  ROLE_ST_TOUR_OVERVIEW_READ: 'ROLE_ST_TOUR_OVERVIEW_READ',
  ROLE_ST_TOUR_OVERVIEW_ADDRESS_READ: 'ROLE_ST_TOUR_OVERVIEW_ADDRESS_READ',
  ROLE_ST_TOUR_OVERVIEW_AI_MODEL_READ: 'ROLE_ST_TOUR_OVERVIEW_AI_MODEL_READ',

  ROLE_ST_TOUR_DETAIL_READ: 'ROLE_ST_TOUR_DETAIL_READ',
  ROLE_ST_TOUR_DETAIL_MEASUREMENT_READ: 'ROLE_ST_TOUR_DETAIL_MEASUREMENT_READ',
  ROLE_ST_TOUR_DETAIL_ADDRESS_READ: 'ROLE_ST_TOUR_DETAIL_ADDRESS_READ',
  ROLE_ST_TOUR_DETAIL_DUPLICATED_READ: 'ROLE_ST_TOUR_DETAIL_DUPLICATED_READ',
  ROLE_ST_TOUR_DETAIL_RFID_DESC_READ: 'ROLE_ST_TOUR_DETAIL_RFID_DESC_READ',
  ROLE_ST_TOUR_DETAIL_REMOVE: 'ROLE_ST_TOUR_DETAIL_REMOVE',
  ROLE_ST_TOUR_DETAIL_GREY_BIN_DESC_READ: 'ROLE_ST_TOUR_DETAIL_GREY_BIN_DESC_READ',

  ROLE_ST_TOUR_COLLECTOR_READ: 'ROLE_ST_TOUR_COLLECTOR_READ',
  ROLE_ST_TOUR_COLLECTOR_WRITE: 'ROLE_ST_TOUR_COLLECTOR_WRITE',
  ROLE_ST_TOUR_COLLECTOR_REMOVE: 'ROLE_ST_TOUR_COLLECTOR_REMOVE',
  ROLE_ST_TOUR_COLLECTOR_SPLIT: 'ROLE_ST_TOUR_COLLECTOR_SPLIT',

  ROLE_ST_DEEP_SCAN_SETTINGS_READ: 'ROLE_ST_DEEP_SCAN_SETTINGS_READ',
  ROLE_ST_DEEP_SCAN_SETTINGS_WRITE: 'ROLE_ST_DEEP_SCAN_SETTINGS_WRITE', 
  ROLE_ST_DEEP_SCAN_SETTINGS_REMOVE: 'ROLE_ST_DEEP_SCAN_SETTINGS_REMOVE',
  ROLE_ST_DEEP_SCAN_SETTINGS_NUMBER_OF_RECEIVES_READ: 'ROLE_ST_DEEP_SCAN_SETTINGS_NUMBER_OF_RECEIVES_READ',

  ROLE_ST_ANALYSIS_QUALITY_DISTRIBUTION_READ: 'ROLE_ST_ANALYSIS_QUALITY_DISTRIBUTION_READ',

  ROLE_SCANTEC_API_READ: 'ROLE_SCANTEC_API_READ',
  ROLE_ST_API_MEASUREMENET_READ: 'ROLE_ST_API_MEASUREMENET_READ',
  ROLE_ST_MAP_VIEW: 'ROLE_ST_MAP_VIEW',

  ROLE_ST_CLASSIFIER_SETTINGS_READ: 'ROLE_ST_CLASSIFIER_SETTINGS_READ',
  ROLE_ST_CLASSIFIER_READ: 'ROLE_ST_CLASSIFIER_READ',
  ROLE_ST_CLASSIFIER_WRITE: 'ROLE_ST_CLASSIFIER_WRITE',
  ROLE_ST_CLASSIFIER_REMOVE: 'ROLE_ST_CLASSIFIER_REMOVE',
  ROLE_ST_CLASSIFIER_WRITE_PUBLIC: "ROLE_ST_CLASSIFIER_WRITE_PUBLIC",
  ROLE_ST_CLASSIFIER_REMOVE_PUBLIC: "ROLE_ST_CLASSIFIER_REMOVE_PUBLIC",


  ROLE_ST_REGION_READ: 'ROLE_ST_REGION_READ',
  ROLE_ST_REGION_WRITE: 'ROLE_ST_REGION_WRITE',
  ROLE_ST_REGION_REMOVE: 'ROLE_ST_REGION_REMOVE',
  
  ROLE_ERROR_GROUP_READ: 'ROLE_ERROR_GROUP_READ',
  ROLE_ERROR_GROUP_WRITE: 'ROLE_ERROR_GROUP_WRITE',
  ROLE_ERROR_GROUP_REMOVE: 'ROLE_ERROR_GROUP_REMOVE',
  ROLE_ERROR_GROUP_CHANGE_PRIVACY: 'ROLE_ERROR_GROUP_CHANGE_PRIVACY',

  ROLE_TA_TOUR_READ: 'ROLE_TA_TOUR_READ',
  ROLE_TA_TOUR_WRITE: 'ROLE_TA_TOUR_WRITE',
  ROLE_TA_TOUR_REMOVE: 'ROLE_TA_TOUR_REMOVE',

  ROLE_TA_TOUR_DETAIL_READ: 'ROLE_TA_TOUR_DETAIL_READ',
  ROLE_TA_TOUR_DETAIL_MEASUREMENT_READ: 'ROLE_TA_TOUR_DETAIL_MEASUREMENT_READ',
  ROLE_TA_TOUR_DETAIL_ADDRESS_READ: 'ROLE_TA_TOUR_DETAIL_ADDRESS_READ',
  ROLE_TA_TOUR_DETAIL_DUPLICATED_READ: 'ROLE_TA_TOUR_DETAIL_DUPLICATED_READ',
  ROLE_TA_TOUR_DETAIL_RFID_DESC_READ: 'ROLE_TA_TOUR_DETAIL_RFID_DESC_READ',
  ROLE_TA_TOUR_DETAIL_REMOVE: 'ROLE_TA_TOUR_DETAIL_REMOVE',
  ROLE_TA_TOUR_DETAIL_GREY_BIN_DESC_READ: 'ROLE_TA_TOUR_DETAIL_GREY_BIN_DESC_READ',

  ROLE_GREY_BIN_READ: 'ROLE_GREY_BIN_READ',

  ROLE_TOUR_OVERVIEW_READ: 'ROLE_TOUR_OVERVIEW_READ',
  ROLE_TOUR_OVERVIEW_WRITE: 'ROLE_TOUR_OVERVIEW_WRITE',
  ROLE_TOUR_OVERVIEW_REMOVE: 'ROLE_TOUR_OVERVIEW_REMOVE',
  ROLE_TOUR_OVERVIEW_SPLIT: 'ROLE_TOUR_OVERVIEW_SPLIT',

  ROLE_TOUR_PLAN_READ: 'ROLE_TOUR_PLAN_READ',
  ROLE_TOUR_PLAN_WRITE: 'ROLE_TOUR_PLAN_WRITE',
  ROLE_TOUR_PLAN_REMOVE: 'ROLE_TOUR_PLAN_REMOVE',

  ROLE_TA_MAP_READ: 'ROLE_TA_MAP_READ',
  
  // admin: ['admin'],
  // staff: ['admin', 'staff'],
  // user: ['admin', 'staff', 'user'],
  // onlyGuest: ,
};

export default authRoles;
