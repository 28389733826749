import PASSIVE_VEHICLE_TYPE from "app/types/PassiveVehicleType";
import moment from "moment";

class KocoUtils {
    static numberWithCommas = (x) => {
        if (typeof x === 'undefined' || x == null) {
            return '-';
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
    static convertDiesel = (data) => {
        if (typeof data === 'undefined' || data == null) {
            return '-';
        }
        var val = (parseFloat(data)).toFixed(1).replace('.', ',');
        return val;
    };
    static roundNumber = (data) => {
        if (typeof data === 'undefined' || data == null) {
            return '-';
        }
        var val = (parseFloat(data)).toFixed(1).replace('.', ',');
        return val;
    };
    static convertToKm = (data) => {
        if (typeof data === 'undefined' || data == null) {
            return '-';
        }
        var val = (parseFloat(data) / 1000).toFixed(1).replace('.', ',');
        return KocoUtils.numberWithCommas(val);
    };
    static formatDate = function (date, format) {
        if (typeof date === 'undefined' || !date)
            return '-';

        if (typeof format !== 'undefined' && format)
            return moment(date).format(format);

        return moment(date).format('DD.MM.YYYY HH:mm');
    };
    static reorder = function (list, startIndex, endIndex) {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    };
    static hexToSignedInt = function (hex) {
        if (hex.length % 2 != 0) {
            hex = "0" + hex;
        }
        var num = parseInt(hex, 16);
        var maxVal = Math.pow(2, hex.length / 2 * 8);
        if (num > maxVal / 2 - 1) {
            num = num - maxVal
        }
        return num;
    };

    static getPassiveVehicleType = function (unit) {

        if(unit.isRcv)
            return PASSIVE_VEHICLE_TYPE.RCV;
        
        return PASSIVE_VEHICLE_TYPE.SWEEPER;

    };

    static getParameterBodyType = function (unit) {

        if (!unit || !unit.constructionType)
            return null;

        var vehicleType = null;

        // const Variopress = 1;
        // const Powerpress = 2;
        // const Rotopress = 4;
        // const Sidepress = 8;
        // const Frontpress = 16;
        // const MEDIUM X2 = 32;
        // const MEDIUM X4 = 64;

        var constructiontype = unit.constructionType.toLowerCase();

        if (unit.isRcv == true) {
            if (constructiontype.includes("variopress")) {
                vehicleType = 1;
            } else if (constructiontype.includes("rotopress")) {
                vehicleType = 4;
            } else if (constructiontype.includes("powerpress")) {
                vehicleType = 2;
            } else if (constructiontype.includes("sidepress")) {
                vehicleType = 8;
            } else if (constructiontype.includes("frontpress")) {
                vehicleType = 16;
            } else if (constructiontype.includes("medium x2")) {
                vehicleType = 32;
            } else if (constructiontype.includes("medium x4")) {
                vehicleType = 64;
            } else if (constructiontype.includes("micro")) {
                vehicleType = 110;
            } else {
                vehicleType = -1;
            }
        }
        else { // sweeper

            if (constructiontype.includes("viajet5")) {
                vehicleType = 0;
            }
            else if (constructiontype.includes("viajet6")) {
                vehicleType = 1;
            }
            else if (constructiontype.includes("viajet7")) {
                vehicleType = 2;
            }
            else if (constructiontype.includes("viajet8")) {
                vehicleType = 3;
            }
            else if (constructiontype.includes("viajet12")) {
                vehicleType = 4;
            }
            else if (constructiontype.includes("sk500")) {
                vehicleType = 5;
            }
            else if (constructiontype.includes("sk600")) {
                vehicleType = 6;
            }
            else if (constructiontype.includes("sk700")) {
                vehicleType = 7;
            }
            else if (constructiontype.includes("sk800")) {
                vehicleType = 8;
            }
            else if (constructiontype.includes("sk1200")) {
                vehicleType = 9;
            }
            else if (constructiontype.includes("asvac5")) {
                vehicleType = 10;
            }
            else if (constructiontype.includes("asvac6")) {
                vehicleType = 11;
            }
            else if (constructiontype.includes("terrajet")) {
                vehicleType = 12;
            }
            else {
                vehicleType = -1;
            }
        }

        return vehicleType;
    };

    static isSideLoader = function (unit) {
        if (unit.constructionType.toLowerCase() == 'faun sidepress' || unit.constructionType.toLowerCase() == 'zoeller slf xl')
            return true;
        return false;
    };
    static uuidv4 = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    static isValidDate = function (dateTime) {
        if(dateTime instanceof Date){
          return !isNaN(dateTime.getTime());
        } 
        else {
          const d = new Date(dateTime);
          return !isNaN(d.getTime());
        }
    };

    static isToday = (dateTime) => {
   
        const today = new Date();
        const date = new Date(dateTime);

        return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
    };

    static startOfDay = (dateTime) => {

        if(!dateTime)
            return null;

        if(!KocoUtils.isValidDate(dateTime))
            return null;
        
        let date = new Date(dateTime);

        date.setHours(0,0,0,0);
        return date;
    };

    static endOfDay = (dateTime) => {

        if(!dateTime)
            return null;

        if(!KocoUtils.isValidDate(dateTime))
            return null;
        
        let date = new Date(dateTime);
         date.setHours(23,59,59,999);
        return date;
    };

    static isObjEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }
}

export default KocoUtils;