import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStBinEvents = createAsyncThunk('main/stTourDetails/getStBinEvents', async (searchParams) => {
  const response = await axios.post(`api/v1/stbinevents`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      binResult: searchParams.binResult.toString(),
      rfid: searchParams.rfid,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'stTourDetails' } });

  let data = await response.data;
  return { stBinEvents: data.st_bin_events, totalcount: data.totalcount };
});

export const getStBinEventsExport = createAsyncThunk('main/stTourDetails/getStBinEventsExport', async (searchParams) => {

  const response = await axios.post(`api/v1/stbineventsexports`, {
    params:
    {
      offset: 0,
      limit: 0,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      binResult: searchParams.binResult.toString(),
      rfid: searchParams.rfid,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order,
      locale: searchParams.locale,

    },
    headers:
    {
      'X-UI-State': 'stTourDetails'
    }
  },
    {
      responseType: "blob"
    });

  let data = await response.data;
  return data;
});

export const getUnits = createAsyncThunk('main/stTourDetails/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getStTours = createAsyncThunk('main/stTourDetails/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourDetails' } });
  const data = await response.data;

  return data.st_tours;
});

export const getStTourViewFilters = createAsyncThunk('main/stTourDetails/getStTourViewFilters', async (searchParams) => {
  const response = await axios.post(`api/v1/stvehicletoursummaries`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      units: searchParams.unit,
      tours: searchParams.tour,
      binResult: searchParams.binResult,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'filter' } });
  let data = await response.data;

  return { stAnalysis: data.stvehicletoursummaries, totalcount: data.totalcount };
});


const stBinEventAdapter = createEntityAdapter({});

export const { selectAll: selectStBinEvents, selectById: selectStBinEventViewById } =
  stBinEventAdapter.getSelectors((state) => state.main.stTourDetails.stBinEvents);

const stBinEventExportAdapter = createEntityAdapter({});

export const { selectAll: selectStBinEventsExport, selectById: selectStBinEventExportViewById } =
stBinEventExportAdapter.getSelectors((state) => state.main.stTourDetails.stBinEventsExport);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.stTourDetails.stTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.stTourDetails.units);

const stTourViewFiltersAdapter = createEntityAdapter({});

export const { selectAll: selectStTourViewFilters, selectById: selectStTourViewFilterById } =
  stTourViewFiltersAdapter.getSelectors((state) => state.main.stTourDetails.stTourViewFilters);

const stTourDetailsSlice = createSlice({
  name: 'main/stTourDetails',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    rowsPerPage: [10, 30, 50],
    sortModel: [{field: 'date', sort: 'desc'}],
    rangeDate: [null, null],
    selectedUnit: [],
    selectedTour: null,
    selectedBinResult: [],
    rfid: null,
    selectedDatePrevValue: [],
    selectedUnitPrevCount: [],
    unitsCombo: [],
    stToursCombo: [],
    rangeDateCombo: [],
    selectedBinResultCombo: [],
    loadingStBinEvents: false,
    loadingUnits: false,
    loadingStTours: false,
    stBinEventsCount: 0,
    stBinEvents: stBinEventAdapter.getInitialState({ }),
    stTours: stToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
    stTourViewFilters: stTourViewFiltersAdapter.getInitialState({ }),
    loadingStTourViewFilters: false,
    stBinEventsExport: stBinEventExportAdapter.getInitialState({ }),
    loadingStBinEventsExport: false,
    stBinEventsExportError: null,
    isPreFilterSet: false,
  },
  reducers: {
    setstTourDetailsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setRowsPerPage: {
      reducer: (state, action) => {
        state.rowsPerPage = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    setSelectedBinResult: {
      reducer: (state, action) => {
        state.selectedBinResult = action.payload;
      },
    },
    setRfid: {
      reducer: (state, action) => {
        state.rfid = action.payload;
      },
    },
    setSelectedDatePrevValue: {
      reducer: (state, action) => {
        state.selectedDatePrevValue = action.payload;
      },
    },
    setSelectedUnitPrevCount: {
      reducer: (state, action) => {
        state.selectedUnitPrevCount = action.payload;
      },
    },
    setUnitsCombo: {
      reducer: (state, action) => {
        state.unitsCombo = action.payload;
      },
    },
    setStToursCombo: {
      reducer: (state, action) => {
        state.stToursCombo = action.payload;
      },
    },
    setRangeDateCombo: {
      reducer: (state, action) => {
        state.rangeDateCombo = action.payload;
      },
    },
    setSelectedBinResultCombo: {
      reducer: (state, action) => {
        state.selectedBinResultCombo = action.payload;
      },
    },
    setIsPreFilterSet: {
      reducer: (state, action) => {
        state.isPreFilterSet = action.payload;
      },
    },
    resetStTourViewFilters: {
      reducer: (state, action) => {
        stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
      },
    },
    resetStBinEventsExport: {
      reducer: (state, action) => {
        stBinEventExportAdapter.removeAll(state.stBinEventsExport);
        state.stBinEventsExportError = null;
      },
    },
    resetStTourDetailsSlice: {
      reducer: (state, action) => {
        stBinEventExportAdapter.removeAll(state.stBinEventsExport);
        state.stBinEventsExportError = null;
        stBinEventAdapter.removeAll(state.stBinEvents);
        state.stBinEventsFile = null;
        state.isPreFilterSet = false;
      },
    },
  },
  extraReducers: {
    [getStBinEvents.fulfilled](state, { payload }) {
      stBinEventAdapter.setAll(state.stBinEvents, payload.stBinEvents);
      state.stBinEventsCount = payload.totalcount;
      state.loadingStBinEvents = false;
    },
    [getStBinEvents.pending]: (state) => {
      state.loadingStBinEvents = true;
      stBinEventAdapter.removeAll(state.stBinEvents);
    },
    [getStBinEvents.rejected]: (state) => {
      state.loadingStBinEvents = false;
    },

    [getStBinEventsExport.fulfilled](state, { payload }) {
      // stBinEventExportAdapter.setAll(state.stBinEventsExport, payload.stBinEvents);
      // state.stBinEventsExportCount = payload.totalcount;
      state.stBinEventsFile = payload;
      state.loadingStBinEventsExport = false;
    },
    [getStBinEventsExport.pending]: (state) => {
      state.loadingStBinEventsExport = true;
      state.stBinEventsExportCount = 0;
      state.stBinEventsExportError = null;
      state.stBinEventsFile = null;
      // stBinEventExportAdapter.removeAll(state.stBinEventsExport);
    },
    [getStBinEventsExport.rejected]: (state) => {
      state.stBinEventsExportError = { msg: 'st_tour_details_report_cannot_be_generated' };
      state.loadingStBinEventsExport = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getStTourViewFilters.fulfilled](state, { payload }) {
      stTourViewFiltersAdapter.setAll(state.stTourViewFilters, payload.stAnalysis);
      state.stTourViewFiltersCount = payload.totalcount;
      state.loadingStTourViewFilters = false;
    },
    [getStTourViewFilters.pending]: (state) => {
      state.loadingStTourViewFilters = true;
      stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
    },
    [getStTourViewFilters.rejected]: (state) => {
      state.loadingStTourViewFilters = false;
    },
  },
});

export const { setstTourDetailsSearchText, 
  setPage, 
  setPageSize, 
  setRowsPerPage, 
  setSortModel, 
  setRangeDate,
  setSelectedUnit,
  setSelectedTour,
  setSelectedBinResult,
  resetStTourViewFilters,
  setRfid,
  setSelectedDatePrevValue,
  setSelectedUnitPrevCount,
  setUnitsCombo,
  setStToursCombo,
  setRangeDateCombo,
  setSelectedBinResultCombo,
  setIsPreFilterSet,
  resetStBinEventsExport,
  resetStTourDetailsSlice,

} = stTourDetailsSlice.actions;

export default stTourDetailsSlice.reducer;
