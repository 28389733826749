import History from "@history";
import i18n from "i18n";
import axios from "axios";
import AxiosInterceptorUtils from "app/utils/AxiosInterceptorUtils";
import {
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { setDefaultSettings } from "./fuse/settingsSlice";

export const saveLanguage = async (currentUser, language) => {
  const response = await axios.patch(
    `api/v1/users/${currentUser.id}/changelanguages/${language}`
  );
  const resData = await response.data;
  return resData;
};

export const changeLanguage =
  (languageId, currentUser) => (dispatch, getState) => {
    const { direction } = getState().fuse.settings.defaults;

    const newLangDirection = i18n.dir(languageId);

    /*
    If necessary, change theme direction
    */
    if (newLangDirection !== direction) {
      dispatch(setDefaultSettings({ direction: newLangDirection }));
    }
    /*
    Change Language
    */
    return i18n.changeLanguage(languageId).then(() => {
      dispatch(i18nSlice.actions.languageChanged(languageId));
      if (currentUser) {
        const user = {
          ...currentUser,
          locale: languageId,
        };
        saveLanguage(currentUser, languageId);
      }

      // History.push(settingsConfig.loginRedirectUrl);
    });
  };

const i18nSlice = createSlice({
  name: "i18n",
  initialState: {
    language: i18n.options.lng,
  },
  reducers: {
    languageChanged: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const selectCurrLangDir = createSelector(
  [({ i18n: i18nState }) => i18nState.language],
  (language) => {
    return i18n.dir(language);
  }
);

export default i18nSlice.reducer;
