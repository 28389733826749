import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getSubscriptions = createAsyncThunk('main/settingsSubscription/subscriptions/getSubscriptions', async () => {
  const response = await axios.get(`api/v1/subscriptions`, { params: { limit: 0 }});
  const data = await response.data;

  return data.subscriptions;
});

const subscriptionsAdapter = createEntityAdapter({});

export const { selectAll: selectSubscriptions, selectById: selectSubscriptionById } =
  subscriptionsAdapter.getSelectors((state) => state.main.settingsSubscription.subscriptions.subscriptions);

const subscriptionsSlice = createSlice({
  name: 'main/settingsSubscription/subscriptions',
  initialState: {
    searchText: '',
    loadingSubscriptions: false,
    subscriptions: subscriptionsAdapter.getInitialState({ }),
  },
  reducers: {
    setSubscriptionsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getSubscriptions.fulfilled](state, { payload }) {
      subscriptionsAdapter.setAll(state.subscriptions, payload);
      state.loadingSubscriptions = false;
    },
    [getSubscriptions.pending]: (state) => {
      state.loadingSubscriptions = true;
      subscriptionsAdapter.removeAll(state.subscriptions);
      state.loadingSubscriptions = false;
    },
    [getSubscriptions.rejected]: (state) => {
      state.loadingSubscriptions = false;
    },
  },
});

export const { setSubscriptionsSearchText } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
