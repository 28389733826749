import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStTourViews = createAsyncThunk('main/stTourOverview/getStTourViews', async (searchParams) => {
  const response = await axios.get(`api/v1/stvehicletoursummaries`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'stTourOverview' } });
  let data = await response.data;
  if(data.stvehicletoursummaries) {
    data.stvehicletoursummaries = 
    data.stvehicletoursummaries.map(item => { 
      if(item.stTourCalculatedData){
        item.otherMaterial = item.stTourCalculatedData.otherMaterial;
        item.total = item.stTourCalculatedData.total;
        item.okCount = item.stTourCalculatedData.okCount;
        item.middleCount = item.stTourCalculatedData.middleCount;
        item.notOkCount = item.stTourCalculatedData.notOkCount;
        item.notEmptied = item.stTourCalculatedData.notEmptied;
        item.notOkPercentage = item.stTourCalculatedData.notOkPercentage;
      } 
      return item;
    } );
  }

  return { stTourOverview: data.stvehicletoursummaries, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/stTourOverview/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getStTours = createAsyncThunk('main/stTourOverview/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourOverview' } });
  const data = await response.data;

  return data.st_tours;
});

export const getStTourViewFilters = createAsyncThunk('main/stTourOverview/getStTourViewFilters', async (searchParams) => {
  const response = await axios.post(`api/v1/stvehicletoursummaries`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      units: searchParams.unit,
      tours: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'filter' } });
  let data = await response.data;

  return { stAnalysis: data.stvehicletoursummaries, totalcount: data.totalcount };
});


const stTourViewAdapter = createEntityAdapter({});

export const { selectAll: selectStTourViews, selectById: selectStTourViewById } =
  stTourViewAdapter.getSelectors((state) => state.main.stTourOverview.stTourViews);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.stTourOverview.stTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.stTourOverview.units);

const stTourViewFiltersAdapter = createEntityAdapter({});

export const { selectAll: selectStTourViewFilters, selectById: selectStTourViewFilterById } =
  stTourViewFiltersAdapter.getSelectors((state) => state.main.stTourOverview.stTourViewFilters);

const stTourOverviewSlice = createSlice({
  name: 'main/stTourOverview',
  initialState: {
    searchText: '',
    page: 0,
    pageSize: 10,
    sortModel: [{ field: 'dateTime', sort: 'desc' }],
    rangeDate: [null, null],
    selectedUnit: null,
    selectedClassifier: [],
    selectedTour: null,
    csvResult: [],
    selectedDatePrevValue: [],
    unitsCombo: [],
    stToursCombo: [],
    rangeDateCombo: [],
    loadingStTourViews: false,
    loadingUnits: false,
    loadingStTours: false,
    stTourOverviewCount: 0,
    stTourViews: stTourViewAdapter.getInitialState({}),
    stTours: stToursAdapter.getInitialState({}),
    units: unitsAdapter.getInitialState({}),
    stTourViewFilters: stTourViewFiltersAdapter.getInitialState({}),
    loadingStTourViewFilters: false,
  },
  reducers: {
    setstTourOverviewSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    resetStTourViewFilters: {
      reducer: (state, action) => {
        stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedClassifier: {
      reducer: (state, action) => {
        state.selectedClassifier = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    setCsvResult: {
      reducer: (state, action) => {
        state.csvResult = action.payload;
      },
    },
    setSelectedDatePrevValue: {
      reducer: (state, action) => {
        state.selectedDatePrevValue = action.payload;
      },
    },
    setUnitsCombo: {
      reducer: (state, action) => {
        state.unitsCombo = action.payload;
      },
    },
    setStToursCombo: {
      reducer: (state, action) => {
        state.stToursCombo = action.payload;
      },
    },
    setRangeDateCombo: {
      reducer: (state, action) => {
        state.rangeDateCombo = action.payload;
      },
    },
  },

  extraReducers: {
    [getStTourViews.fulfilled](state, { payload }) {
      stTourViewAdapter.setAll(state.stTourViews, payload.stTourOverview);
      state.stTourViewsCount = payload.totalcount;
      state.loadingStTourViews = false;
    },
    [getStTourViews.pending]: (state) => {
      state.loadingStTourViews = true;
      stTourViewAdapter.removeAll(state.stTourViews);
    },
    [getStTourViews.rejected]: (state) => {
      state.loadingStTourViews = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },

    [getStTourViewFilters.fulfilled](state, { payload }) {
      stTourViewFiltersAdapter.setAll(state.stTourViewFilters, payload.stAnalysis);
      state.stTourViewFiltersCount = payload.totalcount;
      state.loadingStTourViewFilters = false;
    },
    [getStTourViewFilters.pending]: (state) => {
      state.loadingStTourViewFilters = true;
      stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
    },
    [getStTourViewFilters.rejected]: (state) => {
      state.loadingStTourViewFilters = false;
    },
  },
});

export const { 
  setstTourOverviewSearchText, 
  resetStTourViewFilters,
  setPage,
  setPageSize,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedClassifier,
  setSelectedTour,
  setCsvResult,
  setSelectedDatePrevValue,
  setUnitsCombo,
  setStToursCombo,
  setRangeDateCombo,

} = stTourOverviewSlice.actions;

export default stTourOverviewSlice.reducer;
