import { Navigate } from "react-router-dom";
import FuseUtils from "@fuse/utils";
import ExampleConfig from "app/main/example/ExampleConfig";
import FuseLoading from "@fuse/core/FuseLoading";
import Error404Page from "app/main/404/Error404Page";
import Login2PageConfig from "app/main/login-2/Login2PageConfig";
import Login3PageConfig from "app/main/login-3/Login3PageConfig";
import authRoles from "app/auth/authRoles";
import FleetOverviewConfig from "app/main/fleet-overview/FleetOverviewConfig";
import ServiceDashboardConfig from "app/main/service-dashboard/ServiceDashboardConfig";
import UnitDetailConfig from "app/main/unit-detail/UnitDetailConfig";
import i18next from "i18next";
import de from "../i18n/de";
import en from "../i18n/en";
import FleetTracking from "../main/fleetTracking/FleetTracking";
import Scantec from "../main/scantec/Scantec";
import ScantecConfig from "app/main/scantec/ScantecConfig";
import FleetTrackingConfig from 'app/main/fleetTracking/FleetTrackingConfig';
import TourAnalysisConfig from 'app/main/tour-analysis/TourAnalysisConfig';
import ReportsConfig from "app/main/reports/ReportsConfig";
import ReportsConfig2 from "../main/reports_2/ReportsConfig";
import SettingsCompanyConfig from 'app/main/settings-company/SettingsCompanyConfig';
import SettingsUserConfig from 'app/main/settings-user/SettingsUserConfig';
import SettingsDepotConfig from 'app/main/settings-depot/SettingsDepotConfig';
import SettingsProfileConfig from 'app/main/settings-profile/SettingsProfileConfig';
import SettingsRoleConfig from 'app/main/settings-role/SettingsRoleConfig';
import SettingsUnitConfig from 'app/main/settings-unit/SettingsUnitConfig';
import SettingsPassiveVehicleGroupConfig from 'app/main/settings-passive-vehicle-group/SettingsPassiveVehicleGroupConfig';
import SettingsSubscriptionConfig from 'app/main/settings-subscription/SettingsSubscriptionConfig';
import SettingsEventInfoConfig from 'app/main/settings-event-info/SettingsEventInfoConfig';
import SettingsStTourOverview from 'app/main/st-tour-overview/SettingsStTourOverviewConfig';
import SettingsScTourPlanConfig from 'app/main/settings-st-tour-plan/SettingsStTourPlanConfig';
import SettingsStTourConfig from "app/main/settings-st-tour/SettingsStTourConfig";
import ApiConfig from "app/main/api/ApiConfig";
import SettingsScTourCollectorConfig from 'app/main/settings-st-tour-collector/SettingsStTourCollectorConfig';
import StTourMapConfig from "app/main/st-tour-map/StTourMapConfig";
import StAnalysisConfig from "app/main/st-analysis/StAnalysisConfig";
import StTourDetailsConfig from "app/main/st-tour-details/StTourDetailsConfig";
import SettingsStTourDetailsConfig from "app/main/settings-st-tour-details/SettingsStTourDetailsConfig";
import SettingsStRegionConfig from "app/main/settings-st-region/SettingsStRegionConfig";
import TestMapConfig from "app/main/testmap/TestMapConfig";
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { hasAccess } from 'app/auth/store/userSlice';
import Impressum from "../main/impressum/impressum";

// import SettingsModuleConfig from 'app/main/settings-module/SettingsModuleConfig';
// import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'common', en);
i18next.addResourceBundle('de', 'common', de);
// i18next.addResourceBundle('tr', 'navigation', tr);

const routeConfigs = [
  ExampleConfig,
  Login2PageConfig,
  Login3PageConfig,
  FleetOverviewConfig,
  FleetTrackingConfig,
  ServiceDashboardConfig,
  UnitDetailConfig,
  ScantecConfig,
  TourAnalysisConfig,
  ReportsConfig,
  ReportsConfig2,
  SettingsCompanyConfig,
  SettingsUserConfig,
  SettingsDepotConfig,
  SettingsRoleConfig,
  SettingsUnitConfig,
  SettingsProfileConfig,
  SettingsPassiveVehicleGroupConfig,
  SettingsSubscriptionConfig,
  SettingsEventInfoConfig,
  ApiConfig,
  SettingsStTourOverview,
  SettingsScTourPlanConfig,
  SettingsStTourConfig,
  SettingsScTourCollectorConfig,
  StTourMapConfig,
  StAnalysisConfig,
  StTourDetailsConfig,
  SettingsStTourDetailsConfig,
  SettingsStRegionConfig,
  TestMapConfig
  
  // SettingsModuleConfig,
   
];


const RoutesConfig = () => {
  const navigate = useNavigate();

  let loginRedirecturl = "";

  if (useSelector(hasAccess([authRoles.ROLE_SUPER_ADMIN]))) {
    loginRedirecturl = "fleetoverview";
  } else if (useSelector(hasAccess([authRoles.ROLE_FLEET_OVERVIEW_READ]))) {
    loginRedirecturl = "fleetoverview";
  } else if (useSelector(hasAccess([authRoles.ROLE_SERVICE_DASHBOARD_READ]))) {
    loginRedirecturl = "servicedashboard";
  } else if (useSelector(hasAccess([authRoles.ROLE_FLEET_TRACKING_READ]))) {
    loginRedirecturl = "fleettracking";
  } else if (useSelector(hasAccess([authRoles.ROLE_TOUR_ANALYZE_READ]))) {
    loginRedirecturl = "touranalysis";
  } else if (useSelector(hasAccess([authRoles.ROLE_REPORTS_READ]))) {
    loginRedirecturl = "reports";
  } else if (useSelector(hasAccess([authRoles.ROLE_SCANTEC_READ]))) {
    loginRedirecturl = "scantec";
  }

  useEffect(() => {
    if (loginRedirecturl) {
      navigate(loginRedirecturl);
    }
  }, [loginRedirecturl, navigate]);

  return null;

}


const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    authRoles.ROLE_USER_READ
  ),
  {
    auth: authRoles.ROLE_USER_READ,
    path: '/',
    element: <RoutesConfig />
  },
  {
    auth: authRoles.ROLE_USER_READ,
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    auth: authRoles.ROLE_USER_READ,
    path: 'impressum',
    element: <Impressum />,
  },
  {
    auth: authRoles.ROLE_USER_READ,
    path: '404',
    element: <Error404Page />,
  },
  {
    auth: 'ROLE_USER_READ',
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
