import Typography from "@mui/material/Typography";
import withReducer from "app/store/withReducer";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "@lodash";
import { motion } from "framer-motion";
import reducer from "../store";
import {
  getUnitFleetTracking,
  setIds,
  setSearch,
} from "./store/FleetTrackingSlice";
import MapWidget from "./Widgets/MapWidget";
import ListWidget from "./Widgets/ListWidget";
import HeaderWidget from "./Widgets/HeaderWidget";
import MileageWidget from "./Widgets/MileageWidget";
import DieselWidget from "./Widgets/DieselWidget";
import AdBlueWidget from "./Widgets/AdBlueWidget";
import TourInfoWidget from "./Widgets/TourInfoWidget";
import TimelineWidget from "./Widgets/TimelineWidget";
import ConsumptionWidget from "./Widgets/ConsumptionWidget";
import FuseLoading from "../../../@fuse/core/FuseLoading";
import MapMarkersWidget from "./Widgets/Map1Widget";
import TourInfoSweeperWidget from "./Widgets/TourInfoSweeperWidget";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";
import { showMessage } from "../../store/fuse/messageSlice";
import HydrogenWidget from "./Widgets/HydrogenWidget";
import BatterieWidget from "./Widgets/BatterieWidget";
import NewMapWidget from "./Widgets/NewMapWidget";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { getAreas } from "../scantec/store/areasScantec";
import TextField from "@mui/material/TextField";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import DatePicker from "@mui/lab/DatePicker";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { getAllGps } from "./store/FleetTrackingGpsAllSlice";
import { getAllLines } from "./store/FleetTrackingLinesAllSlice";
import { Box } from "@mui/system";
import getVehicleIcon from "../../types/kocoIconType";
import { getCalculation } from "./store/FleetTrackingCalculation";
import { useLocation } from "react-router-dom";

function FleetTrackingContent() {
  let now = new Date();
  const { t } = useTranslation("common");
  let [text, setText] = useState([]);
  let [displayDetails, setDisplayDetails] = useState(false);
  let [displayMapFleet, setDisplayMapFleet] = useState(false);
  let [id, setId] = useState(0);
  let [loadTotal, setLoadTotal] = useState(false);
  let [unit, setUnit] = useState({});
  let { searchEntities } = useSelector(
    ({ main }) => main.fleetTracking.unitFleetTracking
  );

  let start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );
  let now2 = moment();
  let end = moment(start).add(1, "days").subtract(1, "seconds");

  let [dataFetched, setDataFetched] = useState(0);
  let [unitSelected, setUnitSelected] = useState({});
  const [from_date, setFromDate] = useState(start);
  const [to_date, setToDate] = useState(end);
  // OR use JSON object with : day, month, year
  const [unixStart, setUnixStart] = useState(null);
  const [unixEnd, setUnixEnd] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const newId = params.get("unitId");
  const [newUnit, setNewUnit] = useState();
  const [value, setValue] = useState();
  const [valueEnd, setValueEnd] = useState();
  const [lastDate, setLastDate] = useState(null);

  const newValue = searchEntities.find((unit) => {
    return newId == unit.id;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setNewUnit(newValue);
    if (newValue) {
      setValue(from_date.format("D.M.YYYY HH:mm"));
      setValueEnd(to_date.format("D.M.YYYY HH:mm"));
      setUnixStart(from_date.unix());
      setUnixEnd(to_date.unix());
      // setId(newId);
      dispatch(
        getAllGps({ id: newId, date: from_date.unix(), date2: to_date.unix() })
      );
      dispatch(
        getAllLines({
          id: newId,
          date: from_date.unix(),
          date2: to_date.unix(),
        })
      );
    }
  }, [newValue]);
  const { data, loading } = useSelector(
    ({ main }) => main.fleetTracking.fleetLoadData
  );
  const container = {
    show: {
      transition: {
        staggerChildren: 0.06,
      },
    },
  };
  useEffect(() => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [data]);
  useEffect(() => {
    if (dataFetched === 1) {
      dispatch(
        showMessage({
          message: t("fleet_tracking_notification_gps_calculation"),
        })
      );
    } else if (dataFetched === 2) {
      dispatch(
        showMessage({ message: t("fleet_tracking_notification_failure_gps") })
      );
    } else if (dataFetched == 3) {
      dispatch(
        showMessage({
          message: t("fleet_tracking_notification_gps_calculation"),
        })
      );
    }
  }, [dataFetched]);
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  let ranges = {
    "1 Hour": [moment(now2).subtract(1, "hours"), moment(now2)],
    "Today Only": [moment(start), moment(end)],
    "Yesterday Only": [
      moment(start).subtract(1, "days"),
      moment(end).subtract(1, "days"),
    ],
    "3 Days": [moment(start).subtract(3, "days"), moment(end)],
    "7 Days": [moment(start).subtract(7, "days"), moment(end)],
    "14 Days": [moment(start).subtract(14, "days"), moment(end)],
    "30 Days": [moment(start).subtract(30, "days"), moment(end)],
  };
  let local = {
    format: "DD.MM.YYYY HH:mm",
    sundayFirst: false,
    days: ["M", "T", "W", "T", "F", "S", "S"],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    fromDate: "From ",
    toDate: "To",
    selectingFrom: "Selecting From",
    selectingTo: "Selecting To",
    maxDate: "Max Date",
    close: "Close",
    apply: "Apply",
    cancel: "Cancel",
  };
  let maxDate = moment(start).add(24, "hour");
  function applyCallback(startDate, endDate) {
    // setNewDate(false);
    setValue(startDate.format("D.M.YYYY HH:mm"));
    setValueEnd(endDate.format("D.M.YYYY HH:mm"));
    setUnixStart(startDate.unix());
    setUnixEnd(endDate.unix());
  }

  function sendRequest(val) {
    if ((id || newId) && unixStart && unixEnd) {
      dispatch(
        getAllGps({ id: newId ? newId : id, date: unixStart, date2: unixEnd })
      );
      dispatch(
        getAllLines({ id: newId ? newId : id, date: unixStart, date2: unixEnd })
      );
    } else {
      if (val && id) {
        dispatch(showMessage({ message: "date is missing" }));
      } else if (val) {
        dispatch(showMessage({ message: "truck selection is missing" }));
      }
    }
  }

  let style = {
    fromDot: { backgroundColor: "rgb(17, 24, 39)" },
    toDot: { backgroundColor: "rgb(17, 24, 39)" },
    fromDate: {
      color: "rgb(255, 255, 255)",
      backgroundColor: "rgb(17, 24, 39)",
    },
    toDate: { color: "rgb(255, 255, 255)", backgroundColor: "rgb(17, 24, 39)" },
    betweenDates: {
      color: "rgb(255, 255, 255)",
      backgroundColor: "rgb(238 238 238)",
    },
    hoverCell: {
      backgroundColor: "rgb(238 238 238)",
      color: "rgb(255, 255, 255)",
      border: "0px",
    },
    customRangeButtons: {
      backgroundColor: "rgb(255, 255, 255)",
      color: "rgb(17, 24, 39)",
      border: "0px",
    },
    customRangeSelected: {
      backgroundColor: "rgb(238 238 238)",
      color: "rgb(17, 24, 39)",
      border: "0px",
    },
    standaloneLayout: { display: "flex", maxWidth: "fit-content" },
  };
  if (loadTotal) {
    return <FuseLoading></FuseLoading>;
  }

  return (
    <div className="w-full mt-40">
      {(displayMapFleet || newUnit) && (
        <motion.div variants={item} className="p-16 sm:p-24">
          <div className="widget w-full pb-32">
            <Stack direction="row" spacing={2}>
              {searchEntities && (
                <Autocomplete
                  noOptionsText={t('layout_default_no_options')}
                  clearText={t('layout_default_clear')}
                  id="unit-select"
                  sx={{ width: 300 }}
                  value={newUnit ? newUnit : unit}
                  className="p-2"
                  options={searchEntities}
                  autoHighlight
                  getOptionLabel={(option) =>
                    `${option.constructionType} /  ${option.nameKoco}`
                  }
                  onChange={(event, value) => {
                    if (item.isActivToday && item.isStillRunning == false) {
                      dispatch(getCalculation({ id: item.id, type: 0 }));
                      dispatch(
                        showMessage({
                          message: t(
                            "fleet_tracking_notification_gps_calculation"
                          ),
                        })
                      );
                    }
                    setNewUnit("");
                    setUnit(value);
                    setId(value.id);
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      className="p-2"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        height="32"
                        width="48"
                        margin={0}
                        src={getVehicleIcon(option.constructionType)}
                        srcSet={getVehicleIcon(option.constructionType)}
                        alt=""
                      />
                      <Stack>
                        <Typography className="text-8 font-semibold w-full">
                          {option.constructionType}
                        </Typography>

                        <Typography className="text-8 w-full">
                          {option.nameKoco}
                        </Typography>
                      </Stack>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("tour_analyse_truck_selection")}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
              <DateTimeRangeContainer
                ranges={ranges}
                start={from_date}
                end={to_date}
                local={local}
                maxDate={maxDate}
                style={style}
                applyCallback={applyCallback}
              >
                {" "}
                <Stack direction="row" spacing={2}>
                  <TextField
                    placeholder={t("scan_tec_date_start")}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={value}
                  ></TextField>
                  <TextField
                    placeholder={t("scan_tec_date_end")}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={valueEnd}
                  ></TextField>
                </Stack>
              </DateTimeRangeContainer>
              <Button
                variant="contained"
                onClick={() => sendRequest(true)}
                endIcon={<SendIcon />}
              >
                {t("layout_default_search")}
              </Button>
            </Stack>
          </div>
        </motion.div>
      )}
      <motion.div
        className="flex flex-col md:flex-row sm:p-8 container"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {displayMapFleet || newUnit ? (
          <div className="flex flex-1 flex-col min-w-0 pt-16">
            <motion.div variants={item} className="widget w-full p-16 pb-32">
              <NewMapWidget
                sendRequest={sendRequest}
                setNewUnit={setNewUnit}
                setUnit={setUnit}
                setSelected={newUnit ? newUnit : unit}
                setDisplayMapFleet={setDisplayMapFleet}
                setId={setId}
                setDataFetched={setDataFetched}
              />
            </motion.div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col min-w-0 pt-16">
            <motion.div variants={item} className="widget w-full p-16 pb-32">
              <MapMarkersWidget setDisplayMapFleet={setDisplayMapFleet} />
            </motion.div>
          </div>
        )}
        {/* {!displayMapFleet && (
          <div className="flex flex-1 flex-col min-w-0 pt-16">
            <motion.div variants={item} className="widget w-full p-16 pb-32">
              <MapMarkersWidget setDisplayMapFleet={setDisplayMapFleet} />
            </motion.div>
          </div>
        )} */}
        <div className="flex flex-wrap w-full md:w-320 pt-16">
          <div className="mb-32 w-full sm:w-1/2 md:w-full">
            <motion.div variants={item} className="widget w-full p-16 pb-32">
              <ListWidget
                setLoadTotal={setLoadTotal}
                setUnitSelected={setUnitSelected}
                setNewUnit={setNewUnit}
                setUnit={setUnit}
                setId={setId}
                setText={setText}
                displayDetails={setDisplayDetails}
                setDisplayMapFleet={setDisplayMapFleet}
                setDataFetched={setDataFetched}
              />
            </motion.div>
          </div>
        </div>
      </motion.div>
      <Typography
        component={motion.div}
        variants={item}
        className="px-24 pb-8 text-18 font-medium"
      >
        {text}
      </Typography>
      {displayDetails && !loading && (
        <motion.div
          className="flex flex-col md:flex-row sm:p-8 container"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <div className="flex flex-1 flex-col min-w-0 pt-16">
            <Typography
              component={motion.div}
              variants={item}
              className="px-16 pb-8 text-18 font-medium"
              color="textSecondary"
            >
              {t("last_date_received")}
            </Typography>

            {unitSelected && unitSelected.chassisTypeKoco != 9 && (
              <div className="flex flex-col sm:flex sm:flex-row pb-32">
                <motion.div
                  variants={item}
                  className="widget flex w-full sm:w-1/3 p-16"
                >
                  <MileageWidget data={data} loading={loading} />
                </motion.div>

                <motion.div
                  variants={item}
                  className="widget flex w-full sm:w-1/3 p-16"
                >
                  <DieselWidget data={data} loading={loading} />
                </motion.div>

                <motion.div
                  variants={item}
                  className="widget w-full sm:w-1/3 p-16"
                >
                  <AdBlueWidget data={data} loading={loading} unitSelected={unitSelected}/>
                </motion.div>
              </div>
            )}

            {unitSelected && unitSelected.chassisTypeKoco == 9 && (
              <div className="flex flex-col sm:flex sm:flex-row pb-32">
                <motion.div
                  variants={item}
                  className="widget flex w-full sm:w-1/3 p-16"
                >
                  <MileageWidget data={data} loading={loading} />
                </motion.div>

                <motion.div
                  variants={item}
                  className="widget flex w-full sm:w-1/3 p-16"
                >
                  <HydrogenWidget data={data} loading={loading} />
                </motion.div>

                <motion.div
                  variants={item}
                  className="widget w-full sm:w-1/3 p-16"
                >
                  <BatterieWidget data={data} loading={loading} />
                </motion.div>
              </div>
            )}

            {unitSelected && unitSelected.chassisTypeKoco != 9 && (
              <Typography
                component={motion.div}
                variants={item}
                className="px-16 pb-8 text-18 font-medium"
                color="textSecondary"
              >
                {t("consumption_over_time")}
              </Typography>
            )}
            {unitSelected && unitSelected.chassisTypeKoco == 9 && (
              <Typography
                component={motion.div}
                variants={item}
                className="px-16 pb-8 text-18 font-medium"
                color="textSecondary"
              >
                {t("mileage_over_time")}
              </Typography>
            )}
            <motion.div variants={item} className="widget w-full p-16 pb-48">
              <ConsumptionWidget unitSelected={unitSelected} />
            </motion.div>
          </div>
          <div className="flex flex-wrap w-full md:w-320 pt-16">
            <div className="mb-32 w-full sm:w-1/2 md:w-full pt-32">
              {unitSelected && unitSelected.isRcv && (
                <motion.div variants={item} className="widget w-full p-16">
                  <TourInfoWidget />
                </motion.div>
              )}
              {unitSelected && unitSelected.isRcv == false && (
                <motion.div variants={item} className="widget w-full p-16">
                  <TourInfoSweeperWidget />
                </motion.div>
              )}
            </div>

            <div className="mb-32 w-full sm:w-1/2 md:w-full">
              <Typography
                component={motion.div}
                variants={item}
                className="px-16 pb-8 text-18 font-medium"
                color="textSecondary"
              >
                {t("fleet_tracking_timeline")}
              </Typography>

              <motion.div variants={item} className="widget w-full p-16">
                <TimelineWidget />
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}

      {displayDetails && loading && <FuseLoading />}
    </div>
  );
}
export default withReducer("main", reducer)(FleetTrackingContent);
