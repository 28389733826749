import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';

export const getErrorCodeVehicles = createAsyncThunk('main/cockpit/unitDetail/getErrorCodeVehicles', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/activerrorcodes`);
  const data = await response.data;
  if (!data)
    return;

  return data.errorcodes;
});

export const getErrorCodeInfos = createAsyncThunk('main/cockpit/unitDetail/getErrorCodeInfos', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/activerrorcodeinfos`);
  const data = await response.data;
  if (!data)
    return;

  return data.errorinfos;
});

const errorCodeVehiclesAdapter = createEntityAdapter({ selectId: (model) => model.id });

export const { selectAll: selectErrorReports, selectById: selectErrorReportById, selectIds: selectErrorReportIds } =
  errorCodeVehiclesAdapter.getSelectors((state) => { return state.main.cockpit.errorReport.errorCodeVehicles; });

const errorInfosAdapter = createEntityAdapter({ selectId: (model) => model.id });

export const { selectAll: selectErrorInfos, selectById: selectErrorInfoId, selectIds: selectErrorInfoIds } =
  errorInfosAdapter.getSelectors((state) => { return state.main.cockpit.errorReport.errorInfos; });

const ErrorReportSlice = createSlice({
  name: 'main/cockpit/unitDetail/errorCodes',
  initialState: {
    searchText: '',
    loadingErrorCodeVehicles: false,
    loadingErrorCodeInfos: false,
    errorCodeVehicles: errorCodeVehiclesAdapter.getInitialState({}),
    errorInfos: errorInfosAdapter.getInitialState({}),
  },
  reducers: {
    setErrorReportSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },

  extraReducers: {
    [getErrorCodeVehicles.fulfilled](state, { payload }) {
      errorCodeVehiclesAdapter.setAll(state.errorCodeVehicles, payload);
      state.loadingErrorCodeVehicles = false;
    },
    [getErrorCodeVehicles.pending](state, { payload }) {
      errorCodeVehiclesAdapter.removeAll(state.errorCodeVehicles);
      state.loadingErrorCodeVehicles = true;
    },
    [getErrorCodeVehicles.rejected](state, { payload }) {
      state.loadingErrorCodeVehicles = false;
    },

    [getErrorCodeInfos.fulfilled](state, { payload }) {
      errorInfosAdapter.setAll(state.errorInfos, payload);
      state.loadingErrorCodeInfos = false;
    },
    [getErrorCodeInfos.pending](state, { payload }) {
      state.loadingErrorCodeInfos = true;
    },
    [getErrorCodeInfos.rejected](state, { payload }) {
      state.loadingErrorCodeInfos = false;
    },
  },
});

export const { setErrorReportSearchText } = ErrorReportSlice.actions;

export default ErrorReportSlice.reducer;
