export const liveDataGeneral = [
    {
        'signalId': 'g1',
        'signalName': 'Kilometerstand Transport',
        'translate': 'cockpit_tab_live_data_rcv_info_km_transport',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'g2',
        'signalName': 'Kilometerstand Sammlung',
        'translate': 'cockpit_tab_live_data_rcv_info_km_collection',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'g3',
        'signalName': 'Kilometerstand Wartung',
        'translate': 'cockpit_tab_live_data_rcv_info_km_waiting',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '20401',
        'signalName': 'Kilometerstand / Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_info_km_last',
        'value': '-',
        'date': '',
        'isSoftware': false
    } //defined
];

export const liveDataGeneralNew = [
    {
        'signalId': 'g1',
        'signalName': 'Kilometerstand Transport',
        'translate': 'cockpit_tab_live_data_rcv_info_km_transport',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'g2',
        'signalName': 'Kilometerstand Sammlung',
        'translate': 'cockpit_tab_live_data_rcv_info_km_collection',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'g3',
        'signalName': 'Kilometerstand Wartung',
        'translate': 'cockpit_tab_live_data_rcv_info_km_waiting',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '30201',
        'signalName': 'Kilometerstand / Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_info_km_last',
        'value': '-',
        'date': '',
        'isSoftware': false
    } //defined
];
export const liveDataGeneralSweeper = [
    {
        'signalId': '110202',
        'signalName': 'Kilometerstand Gesamt',
        'translate': 'cockpit_tab_live_data_sweeper_general_total',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'g1',
        'signalName': 'Gesamt - Sweep',
        'translate': 'cockpit_tab_live_data_sweeper_general_swap_total',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'S09',
        'signalName': 'Sweep Distance',
        'translate': 'cockpit_tab_live_data_sweeper_general_swap_distance',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'S08',
        'signalName': 'Sweep Time',
        'translate': 'cockpit_tab_live_data_sweeper_general_swap_time',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '110808',
        'signalName': 'Füllstand Batterie',
        'translate': 'live_data_soc',
        'value': '-',
        'date': '',
        'unit': '%',
        'isSoftware': false
    }, //defined
    {
        'signalId': '110809',
        'signalName': 'Füllstand H2',
        'translate': 'live_data_h2',
        'value': '-',
        'date': '',
        'unit': '%',
        'isSoftware': false
    }, //defined
];
export const liveDataBodyNew = [
    {
        'signalId': '20301',
        'signalName': 'Betriebsstunden',
        'translate': 'cockpit_tab_live_data_rcv_body_working_hours',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '20302',
        'signalName': 'Status Wartung fällig',
        'translate': 'cockpit_tab_live_data_rcv_body_status_working_hours',
        'value': '-',
        'date': '',
        'isSoftware': true
    },//defined
    {
        'signalId': '20302',
        'signalName': 'Betriebsstunden bis zur nächsten Wartung',
        'translate': 'cockpit_tab_live_data_rcv_body_status_working_until_maintenance',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //not
    {
        'signalId': '20702',
        'signalName': 'Zähler Verdichtungssystem',
        'translate': 'cockpit_tab_live_data_rcv_body_count_compaction',
        'value': '-',
        'date': '',
        'isSoftware': false
    },//defined,
    {
        'signalId': '20801',
        'signalName': 'Total Compaction Time',
        'translate': 'cockpit_tab_live_data_rcv_body_total_compaction',
        'value': '-',
        'date': '',
        'isSoftware': false
    },//defined,
    // {
    //     'signalId': '20016',
    //     'signalName': 'Einstellung Frankionsoptimierte Verdichtung',
    //     'translate': 'cockpit_tab_live_data_rcv_body_optimization_compaction',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // },
    {
        'signalId': '20003',
        'signalName': 'Füllstandslevel Zentralschmieranlage',
        'translate': 'cockpit_tab_live_data_rcv_body_fill_level',
        'value': '-',
        'date': '',
        'isSoftware': false
    },
    {
        'signalId': '20004',
        'signalName': 'Pumpe Zentralschmieranlage',
        'translate': 'cockpit_tab_live_data_rcv_body_pump',
        'value': '-',
        'date': '',
        'isSoftware': false
    }
];
export const liveDataBody = [
    {
        'signalId': '20301',
        'signalName': 'Betriebsstunden',
        'translate': 'cockpit_tab_live_data_rcv_body_working_hours',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '20302',
        'signalName': 'Status Wartung fällig',
        'translate': 'cockpit_tab_live_data_rcv_body_status_working_hours',
        'value': '-',
        'date': '',
        'isSoftware': true
    },//defined
    {
        'signalId': '20302',
        'signalName': 'Betriebsstunden bis zur nächsten Wartung',
        'translate': 'cockpit_tab_live_data_rcv_body_status_working_until_maintenance',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //not
    {
        'signalId': '10703',
        'signalName': 'Zähler Verdichtungssystem',
        'translate': 'cockpit_tab_live_data_rcv_body_count_compaction',
        'value': '-',
        'date': '',
        'isSoftware': false
    },//defined,
    // {
    //     'signalId': '2001B',
    //     'signalName': 'Einstellung Frankionsoptimierte Verdichtung',
    //     'translate': 'cockpit_tab_live_data_rcv_body_optimization_compaction',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // },
    {
        'signalId': '20003',
        'signalName': 'Füllstandslevel Zentralschmieranlage',
        'translate': 'cockpit_tab_live_data_rcv_body_fill_level',
        'value': '-',
        'date': '',
        'isSoftware': false
    },
    {
        'signalId': '20004',
        'signalName': 'Pumpe Zentralschmieranlage',
        'translate': 'cockpit_tab_live_data_rcv_body_pump',
        'value': '-',
        'date': '',
        'isSoftware': false
    }
];
export const liveDataBodySweeper = [
    {
        'signalId': 'S02',
        'signalName': 'Betriebsstunden',
        'translate': 'cockpit_tab_live_data_sweeper_working_hours',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'S06',
        'signalName': 'Status Wartung fällig',
        'translate': 'cockpit_tab_live_data_sweeper_status_working_hours',
        'value': '-',
        'date': '',
        'isSoftware': true
    },//defined
    {
        'signalId': 'S06',
        'signalName': 'Betriebsstunden bis zur nächsten Wartung',
        'translate': 'cockpit_tab_live_data_sweeper_status_working_until_maintenance',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //not
    {
        'signalId': '111301',
        'signalName': 'Main air pressure',
        'translate': 'cockpit_tab_live_data_sweeper_body_air_pressure',
        'value': '-',
        'date': '',
        'isSoftware': false
    },//defined,
    {
        'signalId': '111303',
        'signalName': 'Water tank level',
        'translate': 'cockpit_tab_live_data_sweeper_body_water_tank_level',
        'value': '-',
        'date': '',
        'isSoftware': false
    },
    {
        'signalId': '111305',
        'signalName': 'Hydraulic oil temperature',
        'translate': 'cockpit_tab_live_data_sweeper_body_hydraulic_oil_temperture',
        'value': '-',
        'date': '',
        'isSoftware': false
    },
    {
        'signalId': 'S04',
        'signalName': 'Working hours high pressure pump',
        'translate': 'cockpit_tab_live_data_sweeper_high_pressure_pump',
        'value': '-',
        'date': '',
        'isSoftware': false
    }
];
// Second value is correct.
// first value from today - first value from yesterday (4)
// check later. (3)
// how many hours until a complete service again 1 if value less >= 0)
// add total counters.
export const liveDataChassis = [
    // {
    //     'signalId': '10403',
    //     'signalName': 'Betriebsstunden Motor',
    //     'translate': 'cockpit_tab_live_data_rcv_chassis_working_hours',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // }, //defined
    // {
    //     'signalId': '10502',
    //     'signalName': 'Füllstand Diesel',
    //     'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_diesel',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // },//defined
    // {
    //     'signalId': '10503',
    //     'signalName': 'Füllstand Adblue',
    //     'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_ad_blue',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // }, //defined
    {
        'signalId': '20016',
        'signalName': 'Füllstandswarnung Diesel',
        'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_diesel_warning',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '20017',
        'signalName': 'Füllstandswarnung Adblue',
        'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_adblue_warning',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '20401',
        'signalName': 'Kilometerstand / Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_info_km_last',
        'value': '-',
        'date': '',
        'isSoftware': false
    } //defined

];
export const liveDataChassisNew = [
    // {
    //     'signalId': '20701',
    //     'signalName': 'Betriebsstunden Motor',
    //     'translate': 'cockpit_tab_live_data_rcv_chassis_working_hours',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // }, //defined
    // {
    //     'signalId': '30502',
    //     'signalName': 'Füllstand Diesel',
    //     'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_diesel',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // },//defined
    // {
    //     'signalId': '30503',
    //     'signalName': 'Füllstand Adblue',
    //     'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_ad_blue',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // }, //defined
    {
        'signalId': '30404',
        'signalName': 'Füllstandswarnung Diesel',
        'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_diesel_warning',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '30405',
        'signalName': 'Füllstandswarnung Adblue',
        'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_adblue_warning',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '30201',
        'signalName': 'Kilometerstand / Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_info_km_last',
        'value': '-',
        'date': '',
        'isSoftware': false
    } //defined

];

export const liveDataChassisNewBluePower = [
    // {
    //     'signalId': '20701',
    //     'signalName': 'Betriebsstunden Motor',
    //     'translate': 'cockpit_tab_live_data_rcv_chassis_working_hours',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // }, //defined
     {
         'signalId': '30809',
         'signalName': 'Füllstand H2',
         'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_h2',
         'value': '-',
         'date': '',
         'isSoftware': false
     },//defined
     {
         'signalId': '30808',
         'signalName': 'Füllstand Batterie',
         'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_battery',
         'value': '-',
         'date': '',
         'isSoftware': false
     }, //defined
   /* {
        'signalId': '30404',
        'signalName': 'Füllstandswarnung Diesel',
        'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_diesel_warning',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '30405',
        'signalName': 'Füllstandswarnung Adblue',
        'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_adblue_warning',
        'value': '-',
        'date': '',
        'isSoftware': false
    } //defined*/
    {
        'signalId': '30201',
        'signalName': 'Kilometerstand / Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_info_km_last',
        'value': '-',
        'date': '',
        'isSoftware': false
    } //defined

];

export const liveDataChassisSweeper = [
    // {
    //     'signalId': 'S01',
    //     'signalName': 'Betriebsstunden Motor',
    //     'translate': 'cockpit_tab_live_data_rcv_chassis_working_hours',
    //     'value': '-',
    //     'date': '',
    //     'isSoftware': false
    // }, //defined
    {
        'signalId': '110202',
        'signalName': 'Kilometerstand Gesamt',
        'translate': 'cockpit_tab_live_data_sweeper_general_total',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '110502',
        'signalName': 'Füllstand Diesel',
        'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_diesel',
        'value': '-',
        'date': '',
        'unit': '-',
        'isSoftware': false
    }, // defined
    {
        'signalId': '110503',
        'signalName': 'Füllstand Adblue',
        'translate': 'cockpit_tab_live_data_rcv_chassis_fill_level_ad_blue',
        'value': '-',
        'date': '',
        'unit': '-',
        'isSoftware': false
    }, // defined 
    {
        'signalId': '110808',
        'signalName': 'Füllstand Batterie',
        'translate': 'live_data_soc',
        'value': '-',
        'date': '',
        'unit': '%',
        'isSoftware': false
    }, //defined
    {
        'signalId': '110809',
        'signalName': 'Füllstand H2',
        'translate': 'live_data_h2',
        'value': '-',
        'date': '',
        'unit': '%',
        'isSoftware': false
    }, //defined
];

export const liveDataSweeper = [
    {
        'signalId': 'S0A',
        'signalName': 'Hydrostat Betriebsstunden',
        'translate': 'cockpit_tab_live_data_sweeper_drive_hydrostatic_working_hours',
        'group': 1,
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'S0B',
        'signalName': 'Hydrostat Betriebsstunden bis zur nächsten Wartung',
        'translate': 'cockpit_tab_live_data_sweeper_drive_hydrostatic_working_hours_until_mainteance',
        'group': 1,
        'value': '-',
        'date': '',
        'isSoftware': false
    },//defined
    {
        'signalId': 'd1',
        'signalName': 'Hydrostat Kilometerstand',
        'translate': 'cockpit_tab_live_data_sweeper_drive_hydrostatic_km',
        'group': 1,
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'S03',
        'signalName': 'Aux Engine Betriebsstunden',
        'translate': 'cockpit_tab_live_data_aux_working_hours',
        'group': 2,
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'S07',
        'signalName': 'Aux Engine Betriebsstunden bis zur nächsten Wartung',
        'translate': 'cockpit_tab_live_data_aux_working_hours_until_mainteance',
        'group': 2,
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '112001',
        'signalName': 'Tank Level',
        'translate': 'cockpit_tab_live_data_sweeper_tank_level',
        'group': 2,
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': '110808',
        'signalName': 'State of Charge(%)',
        'translate': 'cockpit_tab_live_data_state_change',
        'group': 3,
        'value': '-',
        'date': '',
        'isSoftware': false
    } //defined

];
export const liveDataLifter = [
    {
        'signalId': 'l2',
        'signalName': 'Tageszähler Betriebsstunden Lifter',
        'translate': 'cockpit_tab_live_data_rcv_lifter_daily_working_hours',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //not defined
    {
        'signalId': 'L01',
        'signalName': 'Betriebsstunden Lifter',
        'translate': 'cockpit_tab_live_data_rcv_lifter_working_hours',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'L02',
        'signalName': 'Betriebsstunden bis zur nächsten Wartung',
        'translate': 'cockpit_tab_live_data_rcv_lifter_working_hours_until_maintenace',
        'value': '-',
        'date': '',
        'isSoftware': false
    },//defined
    {
        'signalId': 'L08',
        'signalName': '4-Radgefäße Kammaufnahme Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_lifter_absolute_kam_bin',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'L0A',
        'signalName': '4-Radgefäße Klapparm Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_lifter_absolute_arm_bin',
        'value': '-',
        'date': '',
        'isSoftware': false
    },//defined
    {
        'signalId': 'L04',
        'signalName': 'Schüttungszähler links Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_lifter_absolute_left_bin',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': 'L06',
        'signalName': 'Schüttungszähler rechts Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_lifter_absolute_right_bin',
        'value': '-'
    },
    {
        'signalId': ['L08', 'L0A'],
        'signalName': '4-Radgefäße Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_lifter_4_wheel_total_bin',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
    {
        'signalId': ['L04', 'L06'],
        'signalName': 'Schüttungszähler Gesamt',
        'translate': 'cockpit_tab_live_data_rcv_lifter_2_wheel_total_bin',
        'value': '-',
        'date': '',
        'isSoftware': false
    }, //defined
];
