const locale = {
  APPLICATIONS: 'Applications',
  EXAMPLE: 'Example',
  DASHBOARDS: 'Dashboards',
  FLEET_OVERVIEW: 'Fleet Overview',
  FLEET_TRACKING: 'Fleet Tracking',
  SERVICE_DASHBOARD: 'Service Dashboard',
  TOUR_ANALYSIS: 'Tour Analysis',
  REPORTS: 'Reports',
  SETTINGS: 'Settings',
  SCANTEC : 'SCANTEC',
  SETTINGS_PROFILE: 'Profile',
  SETTINGS_COMPANIES : 'Company administration',
  SETTINGS_TRUCKS : 'Vehicle administration',
  SETTINGS_MODULES : 'Module administration',
  SETTINGS_ROLES : 'Role administration',
  SETTINGS_USERS : 'User administration',
  SETTINGS_ERROR_REPORTS : 'Error report',
  "settings_profile" : "My profile",
  "menu_settings": "Settings",
  "settings_company_title" : "Company administration",
  "settings_vehicle_title" : "Vehicle administration",
  "settings_module_title" : "Module administration",
  "settings_role_title" : "Role administration",
  "settings_user_title" : "User administration",
  "settings_error_info_title" : "Error report",
  "settings_vehicle_group_title" : "Vehicle group administration",
  "settings_subscription_title" : "Event-Sub. administration",
  "settings_event_info_title" : "Event info admin.",
  "scantec" : "SCANTEC",
  "scantec_settings" : "Settings", 
  "scantec_settings_tourplannig" : "Tour Planning",
  "scantec_settings_tour" : "Tour",
  "scantec_tour_overview" : "Tour Overview",
  "scantec_tour_map" : "Map overview",
  "scantec_settings_tourcollector" : "Tour Collector",
  "scantec_analysis" : "Analysis",
  "scantec_quality_distribution" : "Quality Distribution",
  "scantec_tour_details": "Tour Details",
  "documentation" : "Documentation",
  "scantec_settings_region": "Regions",
  "scantec_settings_tour_details" : "Tour Details Edit",
  "settings_depot_title": "Depot administration",
  "ecotec_fleet": "ECOTEC FLEET",
  "smartscan_classifier": "SmartScan classifier settings",
  "settings_error_code_group_title": "Error group administration",
  "tour_Analyse_2.0": "Tour Analyse 2.0",
  "touranalyse_overview": "Overview",
  "touranalyse_details": "Details",
  "touranalyse_map": "Map",
  "touranalyse_settings": "Settings",
  "touranalyse_settings_tour": "Tour",
  "touranalyse_settings_plannig": "Tour Planning",
  "touranalyse_settings_collector": "Tour Collectors",
};

export default locale;
