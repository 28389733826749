import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStTourOverviews = createAsyncThunk('main/settingsStTourCollector/stTourOverview/getStTourOverviews', async (searchParams) => {
  const response = await axios.get(`api/v1/stvehicletoursummaries`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      unit: searchParams.unit,
      classifier: searchParams.classifier,
      tour: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'stTourOverview' } });
  let data = await response.data;
  if(data.stvehicletoursummaries) {
    data.stvehicletoursummaries = 
    data.stvehicletoursummaries.map(item => { 
      if(item.stTourCalculatedData){
        item.otherMaterial = item.stTourCalculatedData.otherMaterial;
        item.total = item.stTourCalculatedData.total;
        item.okCount = item.stTourCalculatedData.okCount;
        item.middleCount = item.stTourCalculatedData.middleCount;
        item.notOkCount = item.stTourCalculatedData.notOkCount;
        item.notEmptied = item.stTourCalculatedData.notEmptied;
        item.notOkPercentage = item.stTourCalculatedData.notOkPercentage;
      } 
      return item;
    } );
  }

  return { stTourOverview: data.stvehicletoursummaries, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/settingsStTourCollector/stTourOverviews/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const patchStTourPlan = createAsyncThunk('main/settingsStTourCollector/stTourOverviews/patchStTourPlan', async (stTourPlan, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourPlan);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'sttourplan',clone);
  console.log('stTourPlan to patch', payload);

  try {
    const response = await axios.patch(`api/v1/batchsttourplans/${stTourPlan.id}`, payload);
    console.log('response', response);

    const data = await response.data;
    return data;
  } catch (err) {
    console.log('errrrrr', err);
    return rejectWithValue(err.response.data)
  }

});

export const patchBatchStTourOverview = createAsyncThunk('main/settingsStTourCollector/stTourOverviews/patchBatchStTourOverview', async (stTourOverviews, { getState, rejectWithValue }) => {
  const { main } = getState();

  let clone = _.cloneDeep(stTourOverviews.filter(item => item.updated));
  let payload = clone.map((row) => { return (AxiosInterceptorUtils.preparePayload('patch', 'sttouroverview', row)); });

  try {
    const response = await axios.patch(`api/v1/batchstvehicletoursummary`, { st_tour_overviews: payload });
    console.log('response', response);
    const data = await response.data;
    return data;
  } catch (err) {
    console.log('errrrrr', err);
    return rejectWithValue(err.response.data)
  }

});

export const deleteStTourPlan = createAsyncThunk('main/settingsStTourCollector/stTourOverviews/deleteStTourPlan', async (stTourPlan, { getState, rejectWithValue }) => {
  const { main } = getState();

  try {
    const response = await axios.delete(`api/v1/sttourplans/${stTourPlan.id}`);
    const data = await response.data;

    return data;
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
});

export const getStTours = createAsyncThunk('main/settingsStTourCollector/stTourOverviews/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stTourPlan.settings.stTourOverviews' } });
  const data = await response.data;

  return data.st_tours;
});


const stTourOverviewsAdapter = createEntityAdapter({});

export const { selectAll: selectStTourOverviews, selectById: selectStTourPlanById } =
  stTourOverviewsAdapter.getSelectors((state) => state.main.settingsStTourCollector.stTourOverviews.stTourOverviews);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.settingsStTourCollector.stTourOverviews.stTours);

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.settingsStTourCollector.stTourOverviews.units);

const stTourCollectorsSlice = createSlice({
  name: 'main/settingsStTourCollector/stTourOverviews',
  initialState: {
    searchText: '',
    page: 0 , 
    pageSize: 10 , 
    sortModel: [] , 
    rangeDate: [null , null], 
    selectedUnit: null, 
    selectedClassifier: [], 
    selectedTour: null, 
    loadingStTourOverviews: false,
    loadingUnits: false,
    savingStTourPlan: false,
    loadingStTours: false,
    stTourOverviews: stTourOverviewsAdapter.getInitialState({ }),
    dataStTourOverviews: [],
    stTourOverviewsCount: 0,
    stTours: stToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
    isDataDirty: false,
    isPreFilterSet: false,
  },
  reducers: {
    setStTourOverviewsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setIsDataDirty: {
      reducer: (state, action) => {
        state.isDataDirty = action.payload;
      },
    },
    setDataStTourOverviews: {
      reducer: (state, action) => {
        state.dataStTourOverviews = action.payload;
      },
    },
    setPage: {
      reducer: (state, action) => {
        state.page = action.payload;
      },
    },
    setPageSize: {
      reducer: (state, action) => {
        state.pageSize = action.payload;
      },
    },
    setSortModel: {
      reducer: (state, action) => {
        state.sortModel = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setSelectedClassifier: {
      reducer: (state, action) => {
        state.selectedClassifier = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    setIsPreFilterSet: {
      reducer: (state, action) => {
        state.isPreFilterSet = action.payload;
      },
    },
    resetStTourCollectorsSlice: {
      reducer: (state, action) => {
        stTourOverviewsAdapter.removeAll(state.stTourOverviews);
        state.isPreFilterSet = false;
        state.isDataDirty = false;
      },
    },
  },
  extraReducers: {
    [getStTourOverviews.fulfilled](state, { payload }) {
      stTourOverviewsAdapter.setAll(state.stTourOverviews, payload.stTourOverview);
      state.stTourOverviewsCount = payload.totalcount;
      state.loadingStTourOverviews = false;
    },
    [getStTourOverviews.pending]: (state) => {
      state.loadingStTourOverviews = true;
      stTourOverviewsAdapter.removeAll(state.stTourOverviews);
    },
    [getStTourOverviews.rejected]: (state) => {
      state.loadingStTourOverviews = false;
    },

    [patchStTourPlan.fulfilled](state, { payload }) {
      state.savingStTourPlan = false;
    },
    [patchStTourPlan.pending](state, { payload }) {
      state.savingStTourPlan = true;
    },
    [patchStTourPlan.rejected](state, { payload }) {
      state.savingStTourPlan = false;
    },

    [patchBatchStTourOverview.fulfilled](state, { payload }) {
      state.savingStTourPlan = false;
    },
    [patchBatchStTourOverview.pending](state, { payload }) {
      state.savingStTourPlan = true;
    },
    [patchBatchStTourOverview.rejected](state, { payload }) {
      state.savingStTourPlan = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
  },
});

export const { 
  setStTourOverviewsSearchText, 
  setIsDataDirty, 
  setDataStTourOverviews,
  setPage, 
  setPageSize,
  setSortModel,
  setRangeDate,
  setSelectedUnit,
  setSelectedClassifier,
  setSelectedTour,
  setIsPreFilterSet,
  resetStTourCollectorsSlice,

} = stTourCollectorsSlice.actions;

export default stTourCollectorsSlice.reducer;
