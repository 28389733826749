import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import apiUrlConfig from 'app/apiUrlConfig';
import KocoUtils from 'app/utils/KocoUtils';
import MACHINE_TYPE from 'app/types/MachineType';
import REQUEST_PARAMETER_TYPE from 'app/types/RequestParameterType';
import _ from 'lodash';
import { copy } from 'stylis';
import { COMMAND_GATEWAY_STATUS_TYPE } from 'app/types/CommandGatewayStatusType';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';

export const getLifterWriteParameterRequests = createAsyncThunk('main/cockpit/lifterParameterUpdate/getLifterWriteParameterRequests', async (unit, { getState }) => {
  const response = await axios.get(`api/v1/units/${unit.id}/types/${REQUEST_PARAMETER_TYPE.WRITE_LIFTER_PARAMETERS}/parameterrequests`, { params: { inprogress: true } });
  const data = await response.data;
  if (!data)
    return;

  let requests = data.parameterrequests;

  let requestIds = requests.map(function (request) { return request.id; });
  const responseSec = await axios.post(`api/v1/parameterrequestsecondaries`, { ids: requestIds });
  const dataSec = await responseSec.data;

  if (dataSec && dataSec.parameterrequests) {
    _.forEach(requests, function (request) {
      _.forEach(dataSec.parameterrequests, function (secondary) {
        if (request.id == secondary.parentId) {
          if (!request.secondaryRequests)
            request.secondaryRequests = [];
          request.secondaryRequests.push(secondary);
        }
      });
    });
  }

  _.forEach(requests, function (request) {
    if (!request.secondaryRequests)
      request.secondaryRequests = [];
  });

  return requests;
});

export const getLifterWriteRequestParameterInfos = createAsyncThunk('main/cockpit/lifterParameterUpdate/getLifterWriteRequestParameterInfos', async (requestParameter, { getState }) => {
  const { main } = getState();
  const { unit, lifterInformation } = main.cockpit.unitDetail;
  let ids = [];
  _.forEach(requestParameter.parameterRequestDetails, function (detail) {
      if (detail.number) {
          ids.push(detail.number);
      }
  });
  const responseParamBatchRead = await axios.post(`api/v1/parameterbatchreads`,
    {
      ids: ids,
      vehicletype: lifterInformation.lifterType,
      machinetype: MACHINE_TYPE.BODY,
    }
  );

  const data = await responseParamBatchRead.data;

  if (!data)
    return;

  let parameters = await data.parameters;

  let requestParameterCopy = _.cloneDeep(requestParameter);

  if (requestParameter &&
    requestParameter.parameterRequestDetails &&
    requestParameter.parameterRequestDetails.length > 0 &&
    parameters && parameters.length > 0) {

    requestParameterCopy.parameterRequestDetails = requestParameterCopy.parameterRequestDetails.map(detail => {
      if (parameters) {
        let parameter = parameters.find(param => param.parameterNumber == detail.number);
        detail.parameter = parameter;
      }
      if (!detail.id)
        detail.id = KocoUtils.uuidv4();

        return detail;
    });
  }

  return {
    id: requestParameter.id,
    changes: {
      loadingChanges: false,
      parameterRequestDetails: requestParameterCopy.parameterRequestDetails,
    }
  }
});

export const getLifterParameterInfoByNumber = createAsyncThunk('main/cockpit/lifterParameterUpdate/getLifterParameterInfoByNumber', async (info, { getState }) => {
  const { main } = getState();
  const { unit, lifterInformation } = main.cockpit.unitDetail;
  const {detail, request} = info;
  let data;
  if (detail.newNumber && detail.newNumber >= 0) {
    const response = await axios.get(`api/v1/parameterbynumbers/${detail.newNumber}`,
      {
        params:
        {
          bodytype: KocoUtils.getParameterBodyType(unit),
          vehicletype: KocoUtils.getPassiveVehicleType(unit),
          machinetype: MACHINE_TYPE.LIFTER,
        }
      });
    data = await response.data;
  }

  let copyRequest = _.cloneDeep(request);
  if(copyRequest.parameterRequestDetails && copyRequest.parameterRequestDetails.length > 0){
    copyRequest.parameterRequestDetails.forEach(det => det.isExpanded = false);
    let copyDetail = copyRequest.parameterRequestDetails.find(det => det.number == detail.number);
    copyDetail.number = detail.newNumber;
    copyDetail.isExpanded = true;
    if(data)
      copyDetail.parameter = data;
  }

  return {
    id: request.id,
    changes: {
      loadingChanges: false,
      parameterRequestDetails: copyRequest.parameterRequestDetails,
    }
  };
});

export const patchLifterParameterRequest = createAsyncThunk('main/cockpit/lifterParameterUpdate/patchLifterParameterRequest', async (lifterParameterRequest, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(lifterParameterRequest);
  let payload = AxiosInterceptorUtils.preparePayload('patch', 'parameterrequests',clone);

  const response = await axios.patch(`api/v1/parameterrequests/${lifterParameterRequest.id}`, payload);

  const data = await response.data;

  return data;
});

export const postLifterParameterRequest = createAsyncThunk('main/cockpit/lifterParameterUpdate/postLifterParameterRequest', async (lifterParameterRequest, { getState }) => {
  const { main } = getState();
  const unit = main.cockpit.unitDetail.unit;

  let clone = _.cloneDeep(lifterParameterRequest);
  let payload = AxiosInterceptorUtils.preparePayload('post', 'parameterrequests', clone);

  const response = await axios.post(`api/v1/parameterrequests`, payload);

  const data = await response.data;

  return data;
});

export const changeLifterParameterRequestStatus = createAsyncThunk('main/cockpit/lifterParameterUpdate/changeLifterParameterRequestStatus', async (lifterParameterRequest, { getState }) => {

  const response = await axios.patch(`api/v1/parameterrequests/${lifterParameterRequest.id}/changestatus`);
  const data = await response.data;

  return data;
});

export const deleteLifterParameterRequest = createAsyncThunk('main/cockpit/lifterParameterUpdate/deleteLifterParameterRequest', async (lifterParameterRequest, { getState }) => {

  const response = await axios.delete(`api/v1/parameterrequests/${lifterParameterRequest.id}`);
  const data = await response.data;

  return data;
});

export const patchInquiryLifterParameterStatus = createAsyncThunk('main/cockpit/lifterParameterUpdate/patchInquiryLifterParameterStatus', async (lifterParameterRequest, { getState }) => {

  const response = await axios.patch(`api/v1/parameterrequests/${lifterParameterRequest.id}/inquirystatus`);
  const data = await response.data;

  return data;
});

export const patchCancelLifterParameterRequest = createAsyncThunk('main/cockpit/lifterParameterUpdate/patchCancelLifterParameterRequest', async (lifterParameterRequest, { getState }) => {

  const response = await axios.patch(`api/v1/parameterrequests/${lifterParameterRequest.id}/cancel`);
  const data = await response.data;

  return data;
});

export const postAllExistingLifterParameterRequest = createAsyncThunk('main/cockpit/lifterParameterUpdate/postAllExistingLifterParameterRequest', async (lifterParameterRequest, { getState }) => {

  const response = await axios.post(`api/v1/units/${lifterParameterRequest.id}/machinetypes/${MACHINE_TYPE.BODY}/sendallparameters`);
  const data = await response.data;

  return data;
});

const lifterWriteParameterRequestsAdapter = createEntityAdapter({ selectId: (model) => model.id });
export const { selectAll: selectLifterWriteParameterRequests, selectById: selectLifterWriteParameterRequestById, selectIds: selectLifterWriteParameterRequestIds } =
  lifterWriteParameterRequestsAdapter.getSelectors((state) => { return state.main.cockpit.lifterParameterUpdate.lifterWriteParameterRequests; });

const LifterParameterUpdateSlice = createSlice({
  name: 'main/cockpit/lifterParameterUpdate',
  initialState: {
    searchText: '',
    loadingLifterWriteParameterRequests: false,
    loadingInUseLifterParameterCategories: false,
    sendingLifterReadParameterRequest: false,
    lifterWriteParameterRequests: lifterWriteParameterRequestsAdapter.getInitialState({}),
    currentLifterRequest: null,
  },
  reducers: {
    setReadParameterSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setCurrentLifterRequest: {
      reducer: (state, action) => {
        state.currentLifterRequest = action.payload;
      },
    },
    upsertLifterParameterRequest: {
      reducer: (state, action) => {
        lifterWriteParameterRequestsAdapter.upsertOne(state.lifterWriteParameterRequests, action.payload);
      },
    },
  },

  extraReducers: {
    [getLifterWriteParameterRequests.fulfilled](state, { payload }) {
      lifterWriteParameterRequestsAdapter.setAll(state.lifterWriteParameterRequests, payload);
      state.loadingLifterWriteParameterRequests = false;
    },
    [getLifterWriteParameterRequests.pending](state, { payload }) {
      lifterWriteParameterRequestsAdapter.removeAll(state.lifterWriteParameterRequests);
      state.loadingLifterWriteParameterRequests = true;
    },
    [getLifterWriteParameterRequests.rejected](state, { payload }) {
      state.loadingLifterWriteParameterRequests = false;
    },

    [getLifterWriteRequestParameterInfos.fulfilled](state, { meta, payload }) {
      let requestParameter = meta.arg;

      if(requestParameter.commandGatewayStatus == COMMAND_GATEWAY_STATUS_TYPE.NEW_REQUEST){
        state.currentLifterRequest.parameterRequestDetails = payload.changes.parameterRequestDetails;
        state.currentLifterRequest.loadingChanges = payload.changes.loadingChanges;
      }
      else {
        lifterWriteParameterRequestsAdapter.updateOne(state.lifterWriteParameterRequests, payload);
      }
    },
    [getLifterWriteRequestParameterInfos.pending](state, { meta, payload }) {
      let requestParameter = meta.arg;
      let changes = {
        id: requestParameter.id,
        changes: {
          loadingChanges: true,
          parameterVehicleValues: null,
        }
      }
      lifterWriteParameterRequestsAdapter.updateOne(state.lifterWriteParameterRequests, changes);
    },
    [getLifterWriteRequestParameterInfos.rejected](state, { meta, payload }) {
      let requestParameter = meta.arg;
      let changes = {
        id: requestParameter.id,
        changes: {
          loadingChanges: false,
        }
      }
      lifterWriteParameterRequestsAdapter.updateOne(state.lifterWriteParameterRequests, changes);
    },

    [getLifterParameterInfoByNumber.fulfilled](state, { meta, payload }) {
      let info = meta.arg;
      const {detail, request} = info;
      if(request.commandGatewayStatus == COMMAND_GATEWAY_STATUS_TYPE.NEW_REQUEST){
        state.currentLifterRequest.parameterRequestDetails = payload.changes.parameterRequestDetails;
        state.currentLifterRequest.loadingChanges = payload.changes.loadingChanges;
      }
      else {
        lifterWriteParameterRequestsAdapter.updateOne(state.lifterWriteParameterRequests, payload);
      }
    },
    [getLifterParameterInfoByNumber.pending](state, { meta, payload }) {

      // TODO 

      // let requestParameter = meta.arg;
      // let changes = {
      //   id: requestParameter.id,
      //   changes: {
      //     loadingChanges: true,
      //     parameterVehicleValues: null,
      //   }
      // }
      // lifterWriteParameterRequestsAdapter.updateOne(state.lifterWriteParameterRequests, changes);
    },
    [getLifterParameterInfoByNumber.rejected](state, { meta, payload }) {


      // TODO

      // let requestParameter = meta.arg;
      // let changes = {
      //   id: requestParameter.id,
      //   changes: {
      //     loadingChanges: false,
      //   }
      // }
      // lifterWriteParameterRequestsAdapter.updateOne(state.lifterWriteParameterRequests, changes);
    },

    [patchLifterParameterRequest.fulfilled](state, { payload }) {
      state.savingCurrentLifterRequest = false;
    },
    [patchLifterParameterRequest.pending](state, { payload }) {
      state.savingCurrentLifterRequest = true;
    },
    [patchLifterParameterRequest.rejected](state, { payload }) {
      state.savingCurrentLifterRequest = false;
    },

    [postLifterParameterRequest.fulfilled](state, { payload }) {
      state.savingCurrentLifterRequest = false;
      state.currentLifterRequest = null;
    },
    [postLifterParameterRequest.pending](state, { payload }) {
      state.savingCurrentLifterRequest = true;
    },
    [postLifterParameterRequest.rejected](state, { payload }) {
      state.savingCurrentLifterRequest = false;
    },

    [changeLifterParameterRequestStatus.fulfilled](state, { payload }) {
      // TODO
      // state.savingCurrentLifterRequest = false;
    },
    [changeLifterParameterRequestStatus.pending](state, { payload }) {
      // TODO
      // state.savingCurrentLifterRequest = true;
    },
    [changeLifterParameterRequestStatus.rejected](state, { payload }) {
      // TODO
      // state.savingCurrentLifterRequest = false;
    },

    [deleteLifterParameterRequest.fulfilled](state, { payload }) {
      // TODO
      // state.deletingLifterRequest = false;
    },
    [deleteLifterParameterRequest.pending](state, { payload }) {
      // TODO
      // state.deletingLifterRequest = true;
    },
    [deleteLifterParameterRequest.rejected](state, { payload }) {
      // TODO
      // state.deletingLifterRequest = false;
    },   
    
    [patchInquiryLifterParameterStatus.fulfilled](state, { payload }) {
      // TODO
      // state.inquiryLifterParameterStatus = false;
    },
    [patchInquiryLifterParameterStatus.pending](state, { payload }) {
      // TODO
      // state.inquiryLifterParameterStatus = true;
    },
    [patchInquiryLifterParameterStatus.rejected](state, { payload }) {
      // TODO
      // state.inquiryLifterParameterStatus = false;
    },  
    
    [patchCancelLifterParameterRequest.fulfilled](state, { payload }) {
      // TODO
      // state.inquiryLifterParameterStatus = false;
    },
    [patchCancelLifterParameterRequest.pending](state, { payload }) {
      // TODO
      // state.inquiryLifterParameterStatus = true;
    },
    [patchCancelLifterParameterRequest.rejected](state, { payload }) {
      // TODO
      // state.inquiryLifterParameterStatus = false;
    },  

    [postAllExistingLifterParameterRequest.fulfilled](state, { payload }) {
      // TODO
      // state.sendAllExistingLifterParameters = false;
    },
    [postAllExistingLifterParameterRequest.pending](state, { payload }) {
      // TODO
      // state.sendAllExistingLifterParameters = true;
    },
    [postAllExistingLifterParameterRequest.rejected](state, { payload }) {
      console.log(payload);
      // TODO
      // state.sendAllExistingLifterParameters = false;
    }, 
    
  },
});

export const { setReadParameterSearchText, setCurrentLifterRequest, upsertLifterParameterRequest } = LifterParameterUpdateSlice.actions;

export default LifterParameterUpdateSlice.reducer;
