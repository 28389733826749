import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "@lodash";

const initialState = {
  fetchedResults: [],
  load: false,
};

export const postReport = createAsyncThunk(
  "main/reports_2/postReport",
  async (params) => {
    const response = await axios.post("api/v1/newreports", params);
    const data = await response.data;
    return data;
  }
);

export const NewReportsSlice = createSlice({
  name: "reports_2/postReport",
  initialState,
  reducers: {},
  extraReducers: {
    [postReport.pending]: (state) => {
      state.load = true;
      state.fetchedResults = [];
    },
    [postReport.fulfilled]: (state, { payload }) => {
      state.fetchedResults = payload;
      state.load = false;
    },
    [postReport.rejected]: (state) => {
      state.load = false;
    },
  },
});

export const { setFilterResults } = NewReportsSlice.actions;
export const postReducer = NewReportsSlice.reducer;
export default NewReportsSlice.reducer;
