import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import AxiosInterceptorUtils from 'app/utils/AxiosInterceptorUtils';
import axios from 'axios';

export const getStTourViews = createAsyncThunk('main/stAnalysis/getStTourViews', async (searchParams) => {
  const response = await axios.post(`api/v1/stvehicletoursummaries`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      units: searchParams.unit,
      classifier: searchParams.classifier,
      tours: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'stAnalysis' } });
  let data = await response.data;
  if(data.stvehicletoursummaries) {
    data.stvehicletoursummaries = 
    data.stvehicletoursummaries.map(item => { 
      if(item.stTourCalculatedData){
        item.otherMaterial = item.stTourCalculatedData.otherMaterial;
        item.total = item.stTourCalculatedData.total;
        item.okCount = item.stTourCalculatedData.okCount;
        item.middleCount = item.stTourCalculatedData.middleCount;
        item.notOkCount = item.stTourCalculatedData.notOkCount;
        item.notEmptied = item.stTourCalculatedData.notEmptied;
        item.notOkPercentage = item.stTourCalculatedData.notOkPercentage;
      } 
      return item;
    } );
  }

  return { stAnalysis: data.stvehicletoursummaries, totalcount: data.totalcount };
});

export const getStTourViewFilters = createAsyncThunk('main/stAnalysis/getStTourViewFilters', async (searchParams) => {
  const response = await axios.post(`api/v1/stvehicletoursummaries`, { 
    params: 
    { 
      offset: searchParams.offset,
      limit: searchParams.limit,
      fromDateTime: searchParams.fromDateTime,
      toDateTime: searchParams.toDateTime,
      units: searchParams.unit,
      classifier: searchParams.classifier,
      tours: searchParams.tour,
      sort: searchParams.sort,
      order: searchParams.order

     }, headers: { 'X-UI-State': 'filter' } });
  let data = await response.data;
  if(data.stvehicletoursummaries) {
    data.stvehicletoursummaries = 
    data.stvehicletoursummaries.map(item => { 
      if(item.stTourCalculatedData){
        item.otherMaterial = item.stTourCalculatedData.otherMaterial;
        item.total = item.stTourCalculatedData.total;
        item.okCount = item.stTourCalculatedData.okCount;
        item.middleCount = item.stTourCalculatedData.middleCount;
        item.notOkCount = item.stTourCalculatedData.notOkCount;
        item.notEmptied = item.stTourCalculatedData.notEmptied;
        item.notOkPercentage = item.stTourCalculatedData.notOkPercentage;
      } 
      return item;
    } );
  }

  return { stAnalysis: data.stvehicletoursummaries, totalcount: data.totalcount };
});

export const getUnits = createAsyncThunk('main/stAnalysis/getUnits', async () => {
  const response = await axios.get(`api/v1/units`, { params: { limit: 0 }, headers: {'X-UI-State': 'user.settings.trucks'} });
  const data = await response.data;

  return data.units;
});

export const getStTours = createAsyncThunk('main/stAnalysis/getStTours', async () => {
  const response = await axios.get(`api/v1/sttour`, { params: { limit: 0 }, headers: { 'X-UI-State': 'stAnalysis' } });
  const data = await response.data;

  return data.st_tours;
});


const stTourViewAdapter = createEntityAdapter({});

export const { selectAll: selectStTourViews, selectById: selectStTourViewById } =
  stTourViewAdapter.getSelectors((state) => state.main.stAnalysis.stTourViews);

const stTourViewFiltersAdapter = createEntityAdapter({});

export const { selectAll: selectStTourViewFilters, selectById: selectStTourViewFilterById } =
  stTourViewFiltersAdapter.getSelectors((state) => state.main.stAnalysis.stTourViewFilters);

const stToursAdapter = createEntityAdapter({});

export const { selectAll: selectStTours, selectById: selectStTourById } =
  stToursAdapter.getSelectors((state) => state.main.stAnalysis.stTours);
  

const unitsAdapter = createEntityAdapter({});

export const { selectAll: selectUnits, selectById: selectUnitById } =
  unitsAdapter.getSelectors((state) => state.main.stAnalysis.units);

const stAnalysisSlice = createSlice({
  name: 'main/stAnalysis',
  initialState: {

    selectedUnit: [],
    rangeDate: [null, null],
    selectedTour: [],
    selectedUnitPrevCount: [],
    selectedTourPrevCount: [],
    selectedDatePrevValue: [],
    unitsCombo: [],
    stToursCombo: [],
    rangeDateCombo: [],
    searchText: '',
    stTourViewsCount: 0,
    loadingStTourViews: false,
    loadingUnits: false,
    loadingStTours: false,
    stAnalysisCount: 0,
    stTourViewFiltersCount: 0,
    loadingStTourViewFilters: false,
    stTourViews: stTourViewAdapter.getInitialState({ }),
    stTourViewFilters: stTourViewFiltersAdapter.getInitialState({ }),
    stTours: stToursAdapter.getInitialState({ }),
    units: unitsAdapter.getInitialState({ }),
  },
  reducers: {
    setSelectedUnit: {
      reducer: (state, action) => {
        state.selectedUnit = action.payload;
      },
    },
    setRangeDate: {
      reducer: (state, action) => {
        state.rangeDate = action.payload;
      },
    },
    setSelectedTour: {
      reducer: (state, action) => {
        state.selectedTour = action.payload;
      },
    },
    setSelectedUnitPrevCount: {
      reducer: (state, action) => {
        state.selectedUnitPrevCount = action.payload;
      },
    },
    setSelectedTourPrevCount: {
      reducer: (state, action) => {
        state.selectedTourPrevCount = action.payload;
      },
    },
    setSelectedDatePrevValue: {
      reducer: (state, action) => {
        state.selectedDatePrevValue = action.payload;
      },
    },
    setUnitsCombo: {
      reducer: (state, action) => {
        state.unitsCombo = action.payload;
      },
    },
    setStToursCombo: {
      reducer: (state, action) => {
        state.stToursCombo = action.payload;
      },
    },
    setRangeDateCombo: {
      reducer: (state, action) => {
        state.rangeDateCombo = action.payload;
      },
    },
    setstAnalysisSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    resetStTourViewFilters: {
      reducer: (state, action) => {
        stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
      },
    },
  },
  extraReducers: {
    [getStTourViews.fulfilled](state, { payload }) {
      stTourViewAdapter.setAll(state.stTourViews, payload.stAnalysis);
      state.stTourViewsCount = payload.totalcount;
      state.loadingStTourViews = false;
    },
    [getStTourViews.pending]: (state) => {
      state.loadingStTourViews = true;
      stTourViewAdapter.removeAll(state.stTourViews);
    },
    [getStTourViews.rejected]: (state) => {
      state.loadingStTourViews = false;
    },

    [getStTourViewFilters.fulfilled](state, { payload }) {
      stTourViewFiltersAdapter.setAll(state.stTourViewFilters, payload.stAnalysis);
      state.stTourViewFiltersCount = payload.totalcount;
      state.loadingStTourViewFilters = false;
    },
    [getStTourViewFilters.pending]: (state) => {
      state.loadingStTourViewFilters = true;
      stTourViewFiltersAdapter.removeAll(state.stTourViewFilters);
    },
    [getStTourViewFilters.rejected]: (state) => {
      state.loadingStTourViewFilters = false;
    },

    [getStTours.fulfilled](state, { payload }) {
      stToursAdapter.setAll(state.stTours, payload);
      state.loadingStTours = false;
    },
    [getStTours.pending]: (state) => {
      state.loadingStTours = true;
      stToursAdapter.removeAll(state.stTours);
    },
    [getStTours.rejected]: (state) => {
      state.loadingStTours = false;
    },

    [getUnits.fulfilled](state, { payload }) {
      unitsAdapter.setAll(state.units, payload);
      state.loadingUnits = false;
    },
    [getUnits.pending]: (state) => {
      state.loadingUnits = true;
      unitsAdapter.removeAll(state.units);
    },
    [getUnits.rejected]: (state) => {
      state.loadingUnits = false;
    },
  },
});

export const { 
  setstAnalysisSearchText, 
  resetStTourViewFilters,
  setSelectedUnit,
  setRangeDate,
  setSelectedTour,
  setSelectedUnitPrevCount,
  setSelectedTourPrevCount,
  setSelectedDatePrevValue,
  setUnitsCombo,
  setStToursCombo,
  setRangeDateCombo,
} = stAnalysisSlice.actions;

export default stAnalysisSlice.reducer;
